import React, { useState } from "react";
import { Link } from "react-router-dom";
import ImageWithBasePath from "../../core/data/img/ImageWithBasePath";
import { CornerDownLeft } from 'react-feather';
import { all_routes } from "../router/all_routes";
// import { AuthService } from '../../core/services/authService';
import { useAuth } from '../../core/context/AuthContext';
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';

const routes = all_routes
const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isToggle, setIsToggle] = useState(false);
  const { login } = useAuth(); 
  const navigate = useNavigate();


  const handleLogin = async (event: React.FormEvent) => {
    event.preventDefault();
    try {
      await login(email, password);
      toast.success('Logged in successfully!');
      navigate('/index'); // Redirect to desired route
      // Optionally, reset form fields
      setEmail('');
      setPassword('');
    } catch (error: any) {
      console.error('Login failed:', error);
      toast.error(`Login failed: ${error.message || 'Server error'}`);
    }
  };



  return (
    <div className="main-wrapper login-body">
       <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

      {/* Header */}
      <header className="log-header">
        <Link to={routes.homeOne}>
          <ImageWithBasePath
            className="img-fluid logo-dark"
            src="assets/img/logo.svg"
            alt="Logo"
          />
        </Link>
      </header>
      {/* /Header */}
      <div className="login-wrapper">
        <div className="loginbox">
          <div className="login-auth">
            <div className="login-auth-wrap">
              <div className="sign-group">
                <Link to={routes.homeOne} className="btn sign-up">
                  <span>
                  <CornerDownLeft/>
                  </span>{" "}
                  Back To Home
                </Link>
              </div>
              <h1>Sign In</h1>
              <p className="account-subtitle">
                We will send a confirmation code to your email.
              </p>
              <form onSubmit={handleLogin} >
                <div className="input-block">
                  <label className="form-label">
                    Email <span className="text-danger">*</span>
                  </label>
                  <input 
                  type="email" 
                  className="form-control" 
                  placeholder="" 
                   value={email}
                   onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="input-block">
                  <label className="form-label">
                    Password <span className="text-danger">*</span>
                  </label>
                  <div
                      className="pass-group"
                      onClick={() => setIsToggle(!isToggle)}
                    >
                      <input
                        type={isToggle ? "text" : "password"}
                        className="form-control pass-input"
                        placeholder=""
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <span
                        className={`fas toggle-password ${
                          isToggle ? "fa-eye" : "fa-eye-slash"
                        }`}
                      />
                    </div>
                </div>
                <div className="input-block">
                  <Link className="forgot-link" to={routes.forgotPassword}>
                    Forgot Password ?
                  </Link>
                </div>
                <div className="input-block m-0">
                  <label className="custom_check d-inline-flex">
                    <span>Remember me</span>
                    <input type="checkbox" name="remeber" />
                    <span className="checkmark" />
                  </label>
                </div>
                <button type="submit" className="btn btn-outline-light w-100 btn-size mt-1">
                  Sign In
                </button>
                <div className="login-or">
                  <span className="or-line" />
                  <span className="span-or-log">
                    Or, log in with your email
                  </span>
                </div>
                {/* Social Login */}
                <div className="social-login">
                  <Link
                    to="#"
                    className="d-flex align-items-center justify-content-center input-block btn google-login w-100"
                  >
                    <span>
                      <ImageWithBasePath
                        src="assets/img/icons/google.svg"
                        className="img-fluid"
                        alt="Google"
                      />
                    </span>
                    Log in with Google
                  </Link>
                </div>
                <div className="social-login">
                  <Link
                    to="#"
                    className="d-flex align-items-center justify-content-center input-block btn google-login w-100"
                  >
                    <span>
                      <ImageWithBasePath
                        src="assets/img/icons/facebook.svg"
                        className="img-fluid"
                        alt="Facebook"
                      />
                    </span>
                    Log in with Facebook
                  </Link>
                </div>
                {/* /Social Login */}
                <div className="text-center dont-have">
                   <Link to={routes.register}>Register</Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* Footer */}
      <footer className="log-footer">
        <div className="container-fluid">
          {/* Copyright */}
          <div className="copyright">
            <div className="copyright-text">
              <p>© 2023 Dreams Rent. All Rights Reserved.</p>
            </div>
          </div>
          {/* /Copyright */}
        </div>
      </footer>
      {/* /Footer */}
    </div>
  );
};

export default Login;
