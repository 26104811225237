import React, { useContext} from "react";
import ImageWithBasePath from "../../core/data/img/ImageWithBasePath";
import Breadcrumbs from "../common/breadcrumbs";
import { Dropdown } from "primereact/dropdown";
import { Link, useNavigate } from "react-router-dom";
import { all_routes } from "../router/all_routes";
import BASE_URL from '../../config';
import { ReservationContext } from './ReservationContext';

const BookingDetail = () => {
  const routes = all_routes;
  const { reservationData, setReservationData } = useContext(ReservationContext);

  if (!reservationData.car) {
    return <div>Loading...</div>;
  }
  const handleFirstNameChange = (value: string) => {
    setReservationData((prevData) => ({ ...prevData, firstName: value }));
  };

  const handleLastNameChange = (value: string) => {
    setReservationData((prevData) => ({ ...prevData, lastName: value }));
  };

  const handleAddressChange = (value: string) => {
    setReservationData((prevData) => ({ ...prevData, address: value }));
  };
  interface CountryOption {
    name: string;
    code: string;
  }
  
  const handleCountryChange = (value: CountryOption) => {
    setReservationData((prevData) => ({
      ...prevData,
      country: value,
    }));
  };
  

  const handleCityChange = (value: string) => {
    setReservationData((prevData) => ({ ...prevData, city: value }));
  };

  const handleEmailChange = (value: string) => {
    setReservationData((prevData) => ({ ...prevData, email: value }));
  };

  const handlePhoneNumberChange = (value: string) => {
    setReservationData((prevData) => ({ ...prevData, phoneNumber: value }));
  };

  const handleAdditionalInfoChange = (value: string) => {
    setReservationData((prevData) => ({ ...prevData, additionalInfo: value }));
  };

  const handleDrivingLicenceNumberChange = (value: string) => {
    setReservationData((prevData) => ({ ...prevData, drivingLicenceNumber: value }));
  };

  const handleDrivingLicenceDocumentChange = (file: File | null) => {
    setReservationData((prevData) => ({ ...prevData, drivingLicenceDocument: file }));
  };

  const handleTermsAcceptedChange = (value: boolean) => {
    setReservationData((prevData) => ({ ...prevData, termsAccepted: value }));
  };
  const navigate = useNavigate();

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    // Perform any validation if necessary

    // Navigate to the payment page
    navigate(routes.paymentPage); // Update with your actual payment page route
  };
  // const [selectedCountry, setSelectedCountry] = useState(null);

console.log("final reservation data "  , reservationData);
  const country = [{ name: "Morroco" }, { name: "France" } ,{ name: "Spain" }];

  // const navigatePath = () => {
  //   navigate(routes.bookingPayment);
  // };

  return (
    <div>
      <Breadcrumbs title="Checkout" subtitle="Checkout" />
      <div className="booking-new-module">
        <div className="container">
          <div className="booking-wizard-head">
            <div className="row align-items-center">
              <div className="col-xl-4 col-lg-3">
                <div className="booking-head-title">
                  <h4>Reserve Your Car</h4>
                  <p>Complete the following steps</p>
                </div>
              </div>
              <div className="col-xl-8 col-lg-9">
                <div className="booking-wizard-lists">
                  <ul>
                    <li className="active activated">
                      <span>
                        <ImageWithBasePath
                          src="assets/img/icons/booking-head-icon-01.svg"
                          alt="Booking Icon"
                        />
                      </span>
                      <h6>Location &amp; Time</h6>
                    </li>
                    <li className="active activated">
                      <span>
                        <ImageWithBasePath
                          src="assets/img/icons/booking-head-icon-02.svg"
                          alt="Booking Icon"
                        />
                      </span>
                      <h6>Add-Ons</h6>
                    </li>
                    <li className="active">
                      <span>
                        <ImageWithBasePath
                          src="assets/img/icons/booking-head-icon-03.svg"
                          alt="Booking Icon"
                        />
                      </span>
                      <h6>Detail</h6>
                    </li>
                    <li>
                      <span>
                        <ImageWithBasePath
                          src="assets/img/icons/booking-head-icon-04.svg"
                          alt="Booking Icon"
                        />
                      </span>
                      <h6>Checkout</h6>
                    </li>
                    <li>
                      <span>
                        <ImageWithBasePath
                          src="assets/img/icons/booking-head-icon-05.svg"
                          alt="Booking Icon"
                        />
                      </span>
                      <h6>Booking Confirmed</h6>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="booking-detail-info">
            <div className="row">
              <div className="col-lg-4 theiaStickySidebar">
                <div className="booking-sidebar">
                  <div className="booking-sidebar-card">
                  <div className="booking-sidebar-head">
                    <h5>Car Details</h5>
                  </div>
                  <div className="booking-sidebar-body">
                    <div className="booking-car-detail">
                      <span className="car-img">
                        {reservationData.car.images && reservationData.car.images.length > 0 ? (
                          <Link to={`${routes.listingDetails}/${reservationData.car._id}`}>
                            <img
                              src={`${BASE_URL}/api/uploads/${reservationData.car.images[0]}`} // Fetching the first image dynamically
                              className="img-fluid"
                              alt={reservationData.car.name}
                            />
                          </Link>
                        ) : (
                          <div>No Image Available</div>
                        )}
                      </span>
                      <div className="care-more-info">
                        <h5>{reservationData.car.name}</h5>
                        <p>{reservationData.car.location}</p>
                        <Link to={`${routes.listingDetails}/${reservationData.car._id}`}>View Car Details</Link>
                      </div>
                    </div>
                    <div className="booking-vehicle-rates">
                      <ul>
                        <li className="total-rate">
                          <h6>Subtotal</h6>
                          <h5>{reservationData.totalPrice} MAD</h5>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="booking-sidebar-card">
                  <div className="booking-sidebar-head d-flex justify-content-between align-items-center">
                    <h5>Location &amp; Time</h5>
                    <Link
                      to={`${routes.bookingCheckout}/${reservationData.car._id}`}
                      className="d-flex align-items-center"
                    >
                      <i className="bx bx-edit-alt me-2" />
                      Edit
                    </Link>
                  </div>
                  <div className="booking-sidebar-body">
                    <ul className="location-address-info">
                      <li>
                        <h6>Rental Type</h6>
                        <p>{reservationData.rentalType === 'delivery' ? 'Delivery' : 'Self Pickup'}</p>
                      </li>

                      {reservationData.rentalType === 'delivery' ? (
                        <>
                          <li>
                            <h6>Delivery Location &amp; Time</h6>
                            <p>{reservationData.deliveryLocation}</p>
                            <p>{reservationData.startDate?.toLocaleDateString()} - {reservationData.startTime || 'Time not set'}</p>
                          </li>
                          <li>
                            <h6>Return Location &amp; Time</h6>
                            <p>
                              {reservationData.sameReturnLocation
                                ? reservationData.deliveryLocation
                                : reservationData.returnLocation}
                            </p>
                            <p>{reservationData.returnDate?.toLocaleDateString()} - {reservationData.returnTime || 'Time not set'}</p>
                          </li>
                        </>
                      ) : (
                        <>
                          <li>
                            <h6>Pickup Location &amp; Time</h6>
                            <p>{reservationData.car.location}</p>
                            <p>{reservationData.startDate?.toLocaleDateString()} - {reservationData.startTime || 'Time not set'}</p>
                          </li>
                          <li>
                            <h6>Return Location &amp; Time</h6>
                            <p>
                              {reservationData.sameReturnLocation
                                ? reservationData.car.location
                                : reservationData.returnLocation}
                            </p>
                            <p>{reservationData.returnDate?.toLocaleDateString()} - {reservationData.returnTime || 'Time not set'}</p>
                          </li>
                        </>
                      )}
                    </ul>
                  </div>
                </div>

                  
                  <div className="booking-sidebar-card">
                    <div className="booking-sidebar-head d-flex justify-content-between align-items-center">
                      <h5>Coupon</h5>
                      <Link to="#" className="coupon-view">
                        View Coupons
                      </Link>
                    </div>
                    <div className="booking-sidebar-body">
                      <form>
                        <div className="d-flex align-items-center">
                          <div className="form-custom flex-fill">
                            <input
                              type="text"
                              className="form-control mb-0"
                              defaultValue="FIRSTBOOKING"
                            />
                            <Link to="#" className="coupon-close">
                              <span>
                                <i className="bx bx-x" />
                              </span>
                            </Link>
                          </div>
                          <button
                            type="submit"
                            className="btn btn-secondary d-flex align-items-center apply-coupon-btn disabled ms-2"
                          >
                            Apply
                            <i className="feather icon-arrow-right ms-2" />
                          </button>
                        </div>
                        <span className="coupen-applied-offter">
                          <i className="bx bxs-offer" />
                          Saved in this Booking{" "}
                        </span>
                      </form>
                    </div>
                  </div>
                  <div className="total-rate-card">
                    <div className="vehicle-total-price">
                      <h5>Estimated Total</h5>
                      <span>{reservationData.totalPrice} MAD</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="booking-information-main">
                      {/* reservation form starts  */}
                      <form onSubmit={handleSubmit}>
                      <div className="booking-information-card">

                      <div className="booking-info-head justify-content-between">
                        <div className="d-flex align-items-center">
                          <span>
                            <i className="bx bx-add-to-queue" />
                          </span>
                          <h5>Billing Info</h5>
                        </div>
                        {/* <div className="d-flex align-items-center">
                          <h6>Returning customer?</h6>
                          <Link
                            to="#"
                            className="btn btn-secondary ms-3"
                            data-bs-toggle="modal"
                            data-bs-target="#sign_in_modal"
                          >
                            <i className="bx bx-user me-2" />
                            Sign In
                          </Link>
                        </div> */}
                      </div>
                      <div className="booking-info-body">
                        <div className="row">
                          {/* First Name */}
                          <div className="col-md-6">
                            <div className="input-block">
                              <label className="form-label">
                                First Name <span className="text-danger"> *</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter First Name"
                                value={reservationData.firstName}
                                onChange={(e) => handleFirstNameChange(e.target.value)}
                              />
                            </div>
                          </div>

                          {/* Last Name */}
                          <div className="col-md-6">
                            <div className="input-block">
                              <label className="form-label">
                                Last Name <span className="text-danger"> *</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Last Name"
                                value={reservationData.lastName}
                                onChange={(e) => handleLastNameChange(e.target.value)}
                              />
                            </div>
                          </div>

                          {/* Address */}
                          <div className="col-md-12">
                            <div className="input-block">
                              <label className="form-label">
                                Address <span className="text-danger"> *</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Address"
                                value={reservationData.address}
                                onChange={(e) => handleAddressChange(e.target.value)}
                              />
                            </div>
                          </div>

                          {/* Country */}
                          <div className="col-md-6">
                            <div className="input-block">
                              <label className="form-label">
                                Country <span className="text-danger"> *</span>
                              </label>
                              <Dropdown
                                value={reservationData.country}
                                onChange={(e) => handleCountryChange(e.value)}
                                options={country} // Replace with your country options array
                                optionLabel="name"
                                placeholder="Country"
                                className="w-100"
                              />
                            </div>
                          </div>

                          {/* City */}
                          <div className="col-md-6">
                            <div className="input-block">
                              <label className="form-label">
                                City <span className="text-danger"> *</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="City"
                                value={reservationData.city}
                                onChange={(e) => handleCityChange(e.target.value)}
                              />
                            </div>
                          </div>

                          {/* Email Address */}
                          <div className="col-md-6">
                            <div className="input-block">
                              <label className="form-label">
                                Email Address <span className="text-danger"> *</span>
                              </label>
                              <input
                                type="email"
                                className="form-control"
                                placeholder="Enter Email"
                                value={reservationData.email}
                                onChange={(e) => handleEmailChange(e.target.value)}
                              />
                            </div>
                          </div>

                          {/* Phone Number */}
                          <div className="col-md-6">
                            <div className="input-block">
                              <label className="form-label">
                                Phone Number <span className="text-danger"> *</span>
                              </label>
                              <input
                                type="tel"
                                className="form-control"
                                placeholder="Enter Phone Number"
                                value={reservationData.phoneNumber}
                                onChange={(e) => handlePhoneNumberChange(e.target.value)}
                              />
                            </div>
                          </div>

                          {/* Additional Information */}
                          <div className="col-md-12">
                            <div className="input-block">
                              <label className="form-label">Additional Information</label>
                              <textarea
                                className="form-control"
                                placeholder="Enter Additional Information"
                                rows={5}
                                value={reservationData.additionalInfo}
                                onChange={(e) => handleAdditionalInfoChange(e.target.value)}
                              />
                            </div>
                          </div>

                          {/* Driving Licence Number */}
                          <div className="col-md-12">
                            <div className="input-block">
                              <label className="form-label">
                                Driving Licence Number <span className="text-danger"> *</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Driving Licence Number"
                                value={reservationData.drivingLicenceNumber}
                                onChange={(e) => handleDrivingLicenceNumberChange(e.target.value)}
                              />
                            </div>
                          </div>

                          {/* Upload Document */}
                          <div className="col-md-12">
                            <div className="input-block">
                              <label className="form-label">
                                Upload Document <span className="text-danger"> *</span>
                              </label>
                              <div className="profile-uploader">
                                <span className="drag-upload-btn">
                                  <span className="upload-btn">
                                    <i className="bx bx-upload me-2" />
                                    Upload Photo
                                  </span>
                                  or Drag Photo
                                </span>
                                <input
                                  type="file"
                                  accept=".jpg,.jpeg,.png"
                                  onChange={(e) =>
                                    handleDrivingLicenceDocumentChange(e.target.files?.[0] || null)
                                  }
                                />
                                {reservationData.drivingLicenceDocument && (
                                  <div>{reservationData.drivingLicenceDocument.name}</div>
                                )}
                              </div>
                              <p className="img-size-info">
                                The maximum photo size is 4MB. Formats: jpeg, jpg, png.
                              </p>
                            </div>
                          </div>

                          {/* Terms and Conditions */}
                          <div className="col-md-12">
                            <div className="input-block m-0">
                              <label className="custom_check d-inline-flex location-check m-0">
                                <span>
                                  I have Read and Accept Terms &amp; Conditions
                                  <span className="text-danger"> *</span>
                                </span>
                                <input
                                  type="checkbox"
                                  name="termsAccepted"
                                  checked={reservationData.termsAccepted}
                                  onChange={(e) => handleTermsAcceptedChange(e.target.checked)}
                                />
                                <span className="checkmark" />
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Navigation Buttons */}
                    <div className="booking-info-btns d-flex justify-content-end">
                      <Link to={routes.bookingCheckout} className="btn btn-secondary">
                        Back to Add-ons
                      </Link>
                      <Link to={routes.bookingPayment} className="btn btn-primary continue-book-btn">
                      Confirm &amp; Pay Now
                      </Link>
                    
                    </div>
                  </form>   

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal new-modal multi-step fade"
        id="sign_in_modal"
        data-keyboard="false"
        data-backdrop="static"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div className="login-wrapper">
                <div className="loginbox">
                  <div className="login-auth">
                    <div className="login-auth-wrap">
                      <h1>Sign In</h1>
                      <p className="account-subtitle">
                        We&apos;ll send a confirmation code to your email.
                      </p>
                      <form>
                        <div className="input-block">
                          <label className="form-label">
                            Email <span className="text-danger">*</span>
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            placeholder="Enter email"
                          />
                        </div>
                        <div className="input-block">
                          <label className="form-label">
                            Password <span className="text-danger">*</span>
                          </label>
                          <div className="pass-group">
                            <input
                              type="password"
                              className="form-control pass-input"
                              placeholder="............."
                            />
                            <span className="fas fa-eye-slash toggle-password" />
                          </div>
                        </div>
                        <div className="input-block text-end">
                          <Link
                            className="forgot-link"
                            to={routes.forgotPassword}
                          >
                            Forgot Password ?
                          </Link>
                        </div>
                        <div className="input-block m-0">
                          <label className="custom_check d-inline-flex">
                            <span>Remember me</span>
                            <input type="checkbox" name="remeber" />
                            <span className="checkmark" />
                          </label>
                        </div>
                        <Link
                          to={routes.homeOne}
                          className="btn btn-outline-light w-100 btn-size mt-1"
                        >
                          Sign In
                        </Link>
                        <div className="login-or">
                          <span className="or-line" />
                          <span className="span-or-log">
                            Or, log in with your email
                          </span>
                        </div>
                        {/* Social Login */}
                        <div className="social-login">
                          <Link
                            to="#"
                            className="d-flex align-items-center justify-content-center input-block btn google-login w-100"
                          >
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/google.svg"
                                className="img-fluid"
                                alt="Google"
                              />
                            </span>
                            Log in with Google
                          </Link>
                        </div>
                        <div className="social-login">
                          <Link
                            to="#"
                            className="d-flex align-items-center justify-content-center input-block btn google-login w-100"
                          >
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/facebook.svg"
                                className="img-fluid"
                                alt="Facebook"
                              />
                            </span>
                            Log in with Facebook
                          </Link>
                        </div>
                        {/* /Social Login */}
                        <div className="text-center dont-have">
                          Don&apos;t have an account ?{" "}
                          <Link to={routes.register}>Sign Up</Link>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingDetail;
