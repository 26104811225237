import React , { useState} from "react";
import ImageWithBasePath from "../../core/data/img/ImageWithBasePath";
import { Link } from "react-router-dom";
import { CornerDownLeft } from "react-feather";
import { all_routes } from "../router/all_routes";
import AuthService from '../../core/services/authService';

import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';

const routes = all_routes;

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const navigate = useNavigate();

  const handleForgotPassword = async (event) => {
    event.preventDefault(); // Prevent the default form submission
    try {
      const data = await AuthService.forgotPassword(email);
      toast.success(data.message || "Password reset email sent successfully!");
      navigate(all_routes.login); // Redirect user to login page or a confirmation page
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <>
      <div className="main-wrapper login-body">
      <ToastContainer />

        {/* Header */}
        <header className="log-header">
          <Link to={routes.homeOne}>
            <ImageWithBasePath
              className="img-fluid logo-dark"
              src="assets/img/logo.svg"
              alt="Logo"
            />
          </Link>
        </header>
        {/* /Header */}
        <div className="login-wrapper">
          <div className="loginbox">
            <div className="login-auth">
              <div className="login-auth-wrap">
                <div className="sign-group">
                  <Link to={routes.homeOne} className="btn sign-up">
                    <span>
                      <CornerDownLeft />
                    </span>{" "}
                    Back To Home
                  </Link>
                </div>
                <h1>Forgot Password</h1>
                <p className="account-subtitle">
                  Enter your email and we will send you a link to reset your
                  password.
                </p>
                <form onSubmit={handleForgotPassword}>
                <div className="input-block">
                  <label className="form-label">
                    Email Address <span className="text-danger">*</span>
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
                <button
                  type="submit"
                  className="btn btn-outline-light w-100 btn-size"
                >
                  Send Reset Link
                </button>
                <ToastContainer />
              </form>
              </div>
            </div>
          </div>
        </div>
        {/* Footer */}
        <footer className="log-footer">
          <div className="container-fluid">
            {/* Copyright */}
            <div className="copyright">
              <div className="copyright-text">
                <p>© 2023 Dreams Rent. All Rights Reserved.</p>
              </div>
            </div>
            {/* /Copyright */}
          </div>
        </footer>
        {/* /Footer */}
      </div>
    </>
  );
};

export default ForgotPassword;
