import React, { useEffect, useState } from "react";
// import Aos from "aos";
import Breadcrumbs from "../common/breadcrumbs";
import ImageWithBasePath from "../../core/data/img/ImageWithBasePath";
import { Link } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "primereact/resources/primereact.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
// import { userReviewData } from "../../core/data/json/user_review";
import DashboardMenu from "./common/dashboard-menu";
// import axios from 'axios';
import { Rating } from './types'; 
import {toast , ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BASE_URL from '../../config';
import { useAuth } from '../../core/context/AuthContext'; 
import axiosInstance from '../../core/services/axiosInstance';

// import axios from 'axios';
const UserReview = () => {
  const [userReviews, setUserReviews] = useState<Rating[]>([]);
  const [loading, setLoading] = useState<boolean>(true); // Correctly define the state variable for loading
  const [error, setError] = useState<string | null>(null); // Correctly define the state variable for error
  const { isAuthenticated} = useAuth(); 

  useEffect(() => {
    const fetchReviews = async () => {
      //  if (!isAuthenticated) {
      //   setError("You must be logged in to view this data.");
      //   setLoading(false);
      //   return;
      // }

      try {
        const response = await axiosInstance.get(`/api/ratings/client`);
        setUserReviews(response.data);
      } catch (error) {
        console.error("Failed to fetch reviews:", error);
        setError("Failed to fetch reviews.");
      } finally {
        setLoading(false);
      }
    };

    fetchReviews();
  }, [isAuthenticated]);

  const renderCarImage = (rowData) => {
    if (!rowData.carId || !rowData.carId._id || !rowData.carId.images || rowData.carId.images.length === 0) {
      return <p>No image available</p>; // Fallback if car or image data is missing
    }
  
    return (
      <div style={{
        width: '170px', 
        height: '80px', 
        overflow: 'hidden', 
        display: 'flex',
        justifyContent: 'center', 
        alignItems: 'center' 
      }}>
        <Link to={`/cars/${rowData.carId._id}`} className="avatar avatar-lg flex-shrink-0">
          <img
            style={{
              width: '100%', 
              height: 'auto', 
              minWidth: '120px', 
              objectFit: 'contain'
            }}
            src={`${BASE_URL}/api/uploads/${rowData.carId.images[0]}`}
            alt={`Image of ${rowData.carId.name}`}
          />
        </Link>
      </div>
    );
  };
  

const renderCarName = (rowData) => (
  <div className="table-head-name flex-grow-1">
    {rowData.carId && rowData.carId.name ? (
      rowData.carId.name
    ) : (
      <p>Car name unavailable</p>
    )}
  </div>
);

  const renderRating = (rowData: Rating) => (
      <div className="review-rating">
          {[...Array(Math.floor(rowData.averageRating))].map((_, i) => (
              <i key={i} className="fas fa-star filled"></i>
          ))}
          {rowData.averageRating % 1 >= 0.5 && <i className="fas fa-star-half-stroke filled"></i>}
          <span>{rowData.averageRating.toFixed(1)}</span>
      </div>
  );

  const renderReview = (rowData: Rating) => (
      <p>{rowData.comment}</p>
  );

  const renderAction = (rowData: Rating) => (
      <div>
          <button
              onClick={() => handleDeleteReview(rowData._id)}
              className="btn btn-danger btn-sm"
          >
              Delete
          </button>
      </div>
  );

  const handleDeleteReview = async (reviewId: string) => {
    if (!window.confirm("Are you sure you want to delete this review?")) return;

    try {
      await axiosInstance.delete(`/api/ratings/${reviewId}`); // Ensure this endpoint exists
        setUserReviews(prevReviews => prevReviews.filter(review => review._id !== reviewId));
        toast.success("Review deleted successfully!");
    } catch (error) {
        console.error("Failed to delete review:", error);
        toast.error("Failed to delete review.");
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  // useEffect(() => {
  //   Aos.init({ duration: 1200, once: true });
  //   userReviewData.getReviewMedium().then((data: any) => setUserReview(data));
  // }, []);

  return (
    <>
       <ToastContainer  />

      <Breadcrumbs title="User Review" subtitle="User Review" />
      {/* <ToastContainer  /> */}

      <DashboardMenu />
      <div className="content">
        <div className="container">
          
          <div className="row">
            {/* Reviews */}
            <div className="col-lg-12 d-flex">
              <div className="card book-card flex-fill mb-0">
                <div className="card-header">
                  <div className="row align-items-center">
                    <div className="col-md-5">
                      <h4>
                        All Reviews 
                      </h4>
                    </div>
                    <div className="col-md-7 text-md-end">
                      <div className="table-search">
                        <input
                          type="text"
                          placeholder="Search"
                          id="tablefilter"
                          className="inputsearch"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="table-responsive dashboard-table">
                    <DataTable
                          value={userReviews}
                          paginator
                          rows={5}
                          rowsPerPageOptions={[5 , 10, 25, 50]}
                          currentPageReportTemplate="{first} to {last} of {totalRecords}"
                          emptyMessage="You haven't made any reviews yet."
                      >
                       <Column field="carImage" header="Car Image" body={renderCarImage}></Column>
                      <Column field="carName" header="Car Name" body={renderCarName}></Column>
                      {/* <Column field="rentalType" header="Rental Type" ></Column> */}
                      <Column field="review" header="Comment" body={renderReview}></Column>
                      <Column field="averageRating" header="Ratings" body={renderRating}></Column>
                      <Column field="action" header="Action" body={renderAction}></Column>
                  </DataTable>
                  </div>
                </div>
              </div>
            </div>
            {/* /Reviews */}
          </div>
        </div>
      </div>

      {/* Delete Modal */}
      <div
        className="modal new-modal fade"
        id="delete_modal"
        data-keyboard="false"
        data-backdrop="static"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div className="delete-action">
                <div className="delete-header">
                  <h4>Delete Reviews</h4>
                  <p>Are you sure want to delete?</p>
                </div>
                <div className="modal-btn">
                  <div className="row">
                    <div className="col-6">
                      <Link
                        to="#"
                        data-bs-dismiss="modal"
                        className="btn btn-secondary w-100"
                      >
                        Delete
                      </Link>
                    </div>
                    <div className="col-6">
                      <Link
                        to="#"
                        data-bs-dismiss="modal"
                        className="btn btn-primary w-100"
                      >
                        Cancel
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Delete Modal */}
      {/* Custom Date Modal */}
      <div
        className="modal new-modal fade"
        id="custom_date"
        data-keyboard="false"
        data-backdrop="static"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Custom Date</h4>
              <button
                type="button"
                className="close-btn"
                data-bs-dismiss="modal"
              >
                <span>×</span>
              </button>
            </div>
            <div className="modal-body">
              <form action="#">
                <div className="modal-form-group">
                  <label>
                    From Date <span className="text-danger">*</span>
                  </label>
                  <input type="date" className="form-control" />
                </div>
                <div className="modal-form-group">
                  <label>
                    To Date <span className="text-danger">*</span>
                  </label>
                  <input type="date" className="form-control" />
                </div>
                <div className="modal-btn modal-btn-item modal-btn-sm text-end">
                  <Link
                    to="#"
                    data-bs-dismiss="modal"
                    className="btn btn-secondary"
                  >
                    Cancel Booking
                  </Link>
                  <Link
                    to="#"
                    data-bs-dismiss="modal"
                    className="btn btn-primary"
                  >
                    Start Ride
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* /Custom Date Modal */}
      {/* Add Payment Modal */}
      <div
        className="modal new-modal multi-step fade"
        id="add_review"
        data-keyboard="false"
        data-backdrop="static"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Add New Review</h4>
              <button
                type="button"
                className="close-btn"
                data-bs-dismiss="modal"
              >
                <span>×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="review-wrap">
                <div className="row">
                  <div className="col-lg-4 col-md-12">
                    <div className="booking-img-wrap">
                      <div className="book-img">
                        <ImageWithBasePath
                          src="assets/img/cars/car-05.jpg"
                          alt="img"
                        />
                      </div>
                      <div className="book-info">
                        <h6>Chevrolet Camaro</h6>
                        <p>Delivery</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8 col-md-12">
                    <div className="row">
                      <div className="col-md-4">
                        <div className="booking-view">
                          <h6>Rental Type</h6>
                          <p>Hourly</p>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="booking-view">
                          <h6>Total Amount</h6>
                          <p>$3000</p>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="booking-view">
                          <h6>Booked On</h6>
                          <p>15 Sep 2023, 09:30 AM</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <form action="#">
                <div className="rating-wrap">
                  <div className="modal-form-group">
                    <label>
                      Overall Ratings <span className="text-danger">*</span>
                    </label>
                    <div className="rating">
                      <Link to="#" className="fav-icon">
                        <i className="fas fa-star" />
                      </Link>
                      <Link to="#" className="fav-icon">
                        <i className="fas fa-star" />
                      </Link>
                      <Link to="#" className="fav-icon">
                        <i className="fas fa-star" />
                      </Link>
                      <Link to="#" className="fav-icon">
                        <i className="fas fa-star" />
                      </Link>
                      <Link to="#" className="fav-icon">
                        <i className="fas fa-star" />
                      </Link>
                    </div>
                  </div>
                  <div className="modal-form-group">
                    <label>
                      Enter Review <span className="text-danger">*</span>
                    </label>
                    <textarea
                      className="form-control"
                      rows={4}
                      placeholder="Enter Comments"
                      defaultValue={""}
                    />
                  </div>
                </div>
                <div className="modal-btn modal-btn-sm text-end">
                  <Link
                    to="#"
                    data-bs-dismiss="modal"
                    className="btn btn-secondary"
                  >
                    Go Back
                  </Link>
                  <Link
                    to="#"
                    data-bs-dismiss="modal"
                    className="btn btn-primary"
                  >
                    Submit
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* /Add Payment Modal */}
    </>
  );
};

export default UserReview;
