import React, { useEffect, useState } from "react";
import ImageWithBasePath from "../../../core/data/img/ImageWithBasePath";
import Aos from "aos";
import { Link } from "react-router-dom";
import { all_routes } from "../../router/all_routes";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import DashboardMenu from "../common/dashboard-menu";
import {toast , ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axiosInstance from '../../../core/services/axiosInstance';
import { useAuth } from '../../../core/context/AuthContext';
import BASE_URL from '../../../config';

const UserWishList = () => {
  const routes = all_routes;
  // const [selectedItems, setSelectedItems] = useState(Array(10).fill(false));
  // const handleItemClick = (index: number) => {
  //   setSelectedItems((prevSelectedItems) => {
  //     const updatedSelectedItems = [...prevSelectedItems];
  //     updatedSelectedItems[index] = !updatedSelectedItems[index];
  //     return updatedSelectedItems;
  //   });
  // };
  useEffect(() => {
    Aos.init({ duration: 1200, once: true });
  }, []);
  
  // wish list fetching logic 

  const [wishlistCars, setWishlistCars] = useState([]);
  const [wishlist, setWishlist] = useState([]);
  console.log(wishlistCars,wishlist);


  const [error] = useState(null);
  const { isAuthenticated } = useAuth(); 
  useEffect(() => {
    if (isAuthenticated) {
      const fetchWishlistCars = async () => {
        try {
          const response = await axiosInstance.get('/api/wishlist');
          setWishlistCars(response.data);
          // Store wishlist car IDs for easy lookup
          setWishlist(response.data.map((car) => car._id));
        } catch (error) {
          console.error('Error fetching wishlist cars:', error);
        }
      };
  
      fetchWishlistCars();
    } else {
      // Clear wishlist data if not authenticated
      setWishlistCars([]);
      setWishlist([]);
    }
  }, [isAuthenticated]);


  if (error) {
    return <div>{error}</div>;
  }
  const handleWishlistClick = async (carId) => {
    try {
      if (wishlist.includes(carId)) {
        // Remove from wishlist
        await axiosInstance.delete(`/api/wishlist/${carId}`);
        // Update wishlist state to exclude the removed car ID
        setWishlist((prevWishlist) => prevWishlist.filter((id) => id !== carId));
        // Update wishlistCars state to exclude the removed car
        setWishlistCars((prevWishlistCars) => prevWishlistCars.filter((car) => car._id !== carId));
        toast.error('Removed from the WishList successfully!');
      }
    } catch (error) {
      console.error('Error updating wishlist:', error);
    }
  };
  

  return (
    <div>
       <ToastContainer  />

      {/* Breadscrumb Section */}
      <div className="breadcrumb-bar">
        <div className="container">
          <div className="row align-items-center text-center">
            <div className="col-md-12 col-12">
              <h2 className="breadcrumb-title">User Wishlist</h2>
              <nav aria-label="breadcrumb" className="page-breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to={routes.homeOne}>Home</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    User Wishlist
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      {/* /Breadscrumb Section */}
      {/* Dashboard Menu */}
      <DashboardMenu />
      {/* /Dashboard Menu */}
      {/* Page Content */}
      <div className="content dashboard-content">
        <div className="container">
          {/* Content Header */}
          <div className="content-header">
            <h4>Wishlist</h4>
          </div>
          {/* /Content Header */}
          <div className="row">
            {/* Wishlist */}
            <div className="col-md-12">
              <div className="wishlist-wrap">
                <div className="listview-car">
                  {/* car card starts  */}
                  {wishlistCars.length > 0 ? (
                    wishlistCars.map((car) => (
                  <div className="card" key={car._id}    >
                    <div className="blog-widget d-flex">
                      <div className="blog-img">
                        <Link to={routes.listingDetails}>
                        {car.images && car.images.length > 0 ? (
                          <Link to={`${routes.listingDetails}/${car._id}`}>
                            <img
                              src={`${BASE_URL}/api/uploads/${car.images[0]}`} // Display only the first image
                              className="img-fluid"
                              alt={car.name}
                            />
                          </Link>
                        ) : (
                          <div>No Images Available</div>
                        )}
                        </Link>
                        <OverlayTrigger
                          placement="right"
                          overlay={
                            <Tooltip id="tooltip-id">
                              Remove From Wishlist
                            </Tooltip>
                          }
                        >
                           <button
                              className={`fav-icon ${wishlist.includes(car._id) ? 'selected' : ''}`}
                              onClick={() => handleWishlistClick(car._id)}
                            >
                              <i className="feather icon-heart" />
                            </button>

                        </OverlayTrigger>
                      </div>
                      <div className="bloglist-content w-100">
                        <div className="card-body">
                          <div className="blog-list-head d-flex">
                            <div className="blog-list-title">
                              <h3>
                                <Link to={routes.listingDetails}>
                                {car?.name}
                                </Link>
                              </h3>
                              <h6>
                                Category : <span>{car?.brand}</span>
                              </h6>
                            </div>
                            <div className="blog-list-rate">
                              <div className="list-rating">
                                <i className="fas fa-star filled me-1" />
                                <i className="fas fa-star filled me-1" />
                                <i className="fas fa-star filled me-1" />
                                <i className="fas fa-star filled me-1" />
                                <i className="fas fa-star filled me-1" />
                                <span>(5.0)</span>
                              </div>
                              <h6>
                              {car?.pricePerDay}<span>/ Day</span>
                              </h6>
                            </div>
                          </div>
                          <div className="listing-details-group">
                            <ul>
                              <li>
                                <span>
                                  <ImageWithBasePath
                                    src="assets/img/icons/car-parts-05.svg"
                                    alt="Auto"
                                  />
                                </span>
                                <p>{car?.transmission}</p>
                              </li>
                              <li>
                                <span>
                                  <ImageWithBasePath
                                    src="assets/img/icons/car-parts-02.svg"
                                    alt="10 KM"
                                  />
                                </span>
                                <p>{car.mileage} KM</p>
                              </li>
                              <li>
                                <span>
                                  <ImageWithBasePath
                                    src="assets/img/icons/car-parts-03.svg"
                                    alt="Petrol"
                                  />
                                </span>
                                <p>{car.fuelType}</p>
                              </li>
                              <li>
                                <span>
                                  <ImageWithBasePath
                                    src="assets/img/icons/car-parts-04.svg"
                                    alt="Power"
                                  />
                                </span>
                                <p>Normal</p>
                              </li>
                              <li>
                                <span>
                                  <ImageWithBasePath
                                    src="assets/img/icons/car-parts-05.svg"
                                    alt='2018'
                                  />
                                </span>
                                <p>{car.registrationYear}</p>
                              </li>
                              <li>
                                <span>
                                  <ImageWithBasePath
                                    src="assets/img/icons/car-parts-06.svg"
                                    alt="Persons"
                                  />
                                </span>
                                <p>{car.seats} Persons</p>
                              </li>
                            </ul>
                          </div>
                          <div className="blog-list-head list-head-bottom d-flex">
                            <div className="blog-list-title">
                              <div className="title-bottom">
                                <div className="address-info">
                                  <h6>
                                    <i className="feather icon-map-pin me-2" />
                                    {car.location}, {car.city}
                                  </h6>
                                </div>
                              </div>
                            </div>
                            <div className="listing-button">
                              <Link
                                to={routes.listingDetails}
                                className="btn btn-order"
                              >
                                <span>
                                  <i className="feather icon-calendar me-2" />
                                </span>
                                Rent Now
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  ))
                  ) : (
                    <p>Your wishlist is empty.</p>
                  )}
                </div>
                
                
              </div>
              {/* /Wishlist */}
            </div>
          </div>
        </div>
      </div>
      {/* /Page Content */}
    </div>
  );
};

export default UserWishList;
