import React, { useEffect, useState } from "react";
import Aos from "aos";
import Breadcrumbs from "../common/breadcrumbs";
import DashboardMenu from "./common/dashboard-menu";
// import ImageWithBasePath from "../../core/data/img/ImageWithBasePath";
import { Link } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
// import {  userBookingsData } from "../../core/data/json/user_bookings";
import { all_routes } from "../router/all_routes";
import { useAuth } from '../../core/context/AuthContext';
import axiosInstance from '../../core/services/axiosInstance';
import BASE_URL from '../../config';
import moment from 'moment';
const UserBookings = () => {
  const routes = all_routes;
  const [userBookings, setUserBookings] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const { client } = useAuth();

  // Filtered data based on search input
  const filteredData = userBookings.filter((booking) => {
    const values = Object.values(booking).map((value) =>
      value ? value.toString().toLowerCase() : ''
    );
    return values.some((value) => value.includes(searchInput.toLowerCase()));
  });

  // Initialize AOS library
  useEffect(() => {
    Aos.init({ duration: 1200, once: true });
  }, []);

  // Fetch client reservations
  const fetchReservations = async () => {
    if (client && client.clientId) {
      try {
        const response = await axiosInstance.get(
          `/api/reservations/client/${client.clientId}`
        );
        console.log('Reservations fetched successfully:', response.data);
        setUserBookings(response.data.data); // Assuming the data is in the `data` property
      } catch (error) {
        console.error('Failed to fetch reservations:', error);
      }
    }
  };

  useEffect(() => {
    fetchReservations();
  }, [client]);

  // Rendering functions for table columns
  const carName = (res) => {
    return (
      <div         className="bookbyid"
>
        <Link to="#" className="avatar avatar-lg flex-shrink-0">
          {res.car && res.car.images && res.car.images.length > 0 ? (
            <img
              src={`${BASE_URL}/api/uploads/${res.car.images[0]}`}
              alt={res.car.name}
              style={{
                width: '100%', // Ensures the image width fills the container
                height: 'auto', // Adjusts height automatically to maintain aspect ratio
                minWidth: '120px', // Minimum width to cover the container
                objectFit: 'contain', // Ensures the entire image is visible, might add letterboxing
            }}
              // className="avatar-img"
            />
          ) : (
            <span>No Image</span>
          )}
        </Link>
        {/* <div className="table-head-name flex-grow-1">
          <Link to="#">{res.car ? res.car.name : 'No Car Name'}</Link>
          <p>{res.deliveryStatus || ''}</p>
        </div> */}
      </div>
    );
  };

  const delivery = (res) => {
    return (
     
       <div
 
       data-bs-toggle="modal"
       data-bs-target="#upcoming_booking"
       className="bookbyid"
     >
               {res.deliveryLocation || 'N/A'}

     </div>
    );
  };

  const BookingId = (res) => {
    return (
      <Link
        to="#"
        data-bs-toggle="modal"
        data-bs-target="#upcoming_booking"
        className="bookbyid"
      >
        {res.firstName || 'N/A'} 
        { res.lastName || 'N/A'}
      </Link>
    );
  };

  const location = (res) => {
    return (
      <p>
        {res.city || 'N/A'}
        {/* If you have additional location details, include them here */}
      </p>
    );
  };

  const status = (res) => {
    return (
      <span
        className={`${
          res.status === 'Upcoming'
            ? 'badge badge-light-secondary'
            : res.status === 'Inprogress'
            ? 'badge badge-light-warning'
            : res.status === 'Cancelled'
            ? 'badge badge-light-danger'
            : res.status === 'Completed'
            ? 'badge badge-light-success'
            : ''
        }`}
      >
        {res.status || 'Unknown'}
      </span>
    );
  };

  const bookedOn = (res) => {
    return (
      <span>
        {res.startDate
          ? moment(res.startDate).format('YYYY-MM-DD')
          : 'N/A'}
      </span>
    );
  };

  const total = (res) => {
    return <span>{res.totalPrice ? `$${res.totalPrice}` : 'N/A'}</span>;
  };

  const action = (res) => {
    return (
      <div>
        {/* Example action buttons */}
        <Link to={`/reservations/${res._id}`} className="btn btn-primary me-2">
          View
        </Link>
        {/* Add other actions like Edit or Cancel if needed */}
      </div>
    );
  };
 
  return (
    <>
      <Breadcrumbs title="User Bookings" subtitle="User Bookings" />
      <DashboardMenu />

      <div className="content">
        <div className="container">
          {/* Content Header */}
          <div className="content-header">
            <h4>My Bookings</h4>
          </div>
          {/* /Content Header */}
          {/* Sort By */}
          <div className="row">
            <div className="col-lg-12">
              <div className="sorting-info">
                <div className="row d-flex align-items-center">
                  <div className="col-xl-7 col-lg-8 col-sm-12 col-12">
                    <div className="booking-lists">
                      <ul className="nav">
                        <li>
                          <Link className="active" to={routes.userBookings}>
                            All Bookings
                          </Link>
                        </li>
                        <li>
                          <Link to={routes.userBookingInprogress}>
                            Inprogress
                          </Link>
                        </li>
                    
                        <li>
                          <Link to={routes.userBookingCancelled}>
                            Cancelled
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  
                 
                </div>
              </div>
            </div>
          </div>
          {/* Sort By */}
          <div className="row">
            {/* All Bookings */}
            <div className="col-lg-12 d-flex">
              <div className="card book-card flex-fill mb-0">
                <div className="card-header">
                  <div className="row align-items-center">
                    <div className="col-md-5">
                      <h4>
                        All Bookings <span>(count)</span>
                      </h4>
                    </div>
                    <div className="col-md-7 text-md-end">
                      <div className="table-search">
                      <div id="tablefilter">
                          <label>
                            <input
                              type="text"
                              value={searchInput}
                              onChange={(e) => setSearchInput(e.target.value)}
                              placeholder="Search"
                              className="inputsearch"
                            />
                          </label>
                        </div>
                        <Link to={routes.listingGrid} className="btn btn-add">
                          <i className="feather icon-plus-circle" />
                          Add Booking
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                <div className="table-responsive dashboard-table">
                    <DataTable
                      className="table datatable"
                      value={filteredData}
                      paginator
                      rows={10}
                      rowsPerPageOptions={[10, 25, 50]}
                      currentPageReportTemplate="{first}"
                      emptyMessage="No reservations found."
                    >
                      {/* Search Input */}
                      <div className="mb-3">
                        <input
                          type="text"
                          placeholder="Search..."
                          className="form-control"
                          value={searchInput}
                          onChange={(e) => setSearchInput(e.target.value)}
                        />
                      </div>

                      {/* Table Columns */}
                      <Column field="_id" header="Booking ID" body={BookingId}></Column>
                      <Column field="car.name" header="Car " body={carName}></Column>
                      <Column field="rentalType" header="Rental Type"></Column>
                      <Column
                        field="deliveryLocation"
                        header="Pickup / Delivery Location"
                        body={delivery}
                      ></Column>
                      <Column
                        field="returnLocation"
                        header="City"
                        body={location}
                      ></Column>
                      <Column field="startDate" header="Booked On" body={bookedOn}></Column>
                      <Column field="totalPrice" header="Total" body={total}></Column>
                      <Column field="status" header="Status" body={status}></Column>
                      <Column field="action" header="Action" body={action}></Column>
                    </DataTable>
                  </div>
                  <div className="table-footer">
                    <div className="row">
                      <div className="col-md-6">
                        <div id="tablelength" />
                      </div>
                      <div className="col-md-6 text-md-end">
                        <div id="tablepage" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* /All Bookings */}
          </div>
          {/* /Dashboard */}
        </div>
      </div>

      {/* Completed Booking */}
      
      {/* Order Success Modal */}
      
     
      {/* Custom Date Modal */}
      
    </>
  );
};

export default UserBookings;
