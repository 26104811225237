import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import CountUp from "react-countup";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import AOS from "aos";
import "aos/dist/aos.css";
// import type { Dayjs } from "dayjs";
// import dayjs from "dayjs";
// import { TimePicker } from "antd";
import ImageWithBasePath from "../../../core/data/img/ImageWithBasePath";
import { all_routes } from "../../router/all_routes";

import Footer from "../../common/footer";
import Header from "../../common/header";
import BASE_URL from '../../../config';
import { toast, ToastContainer } from 'react-toastify';
import axios from 'axios';
import { useAuth } from '../../../core/context/AuthContext';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../../core/services/axiosInstance';
import 'react-toastify/dist/ReactToastify.css';
import SearchSection from "../../common/search";

const HomeOne = () => {
  const routes = all_routes;

  const [selectedItems, setSelectedItems] = useState(Array(10).fill(false));


  const { isAuthenticated } = useAuth(); // Access authentication status
  const navigate = useNavigate(); // For programmatic navigation


  const [cars, setCars] = useState([]);
  const [wishlistCars, setWishlistCars] = useState([]);

  const [wishlist, setWishlist] = useState([]);
  console.log(wishlistCars);

  // const onChange = (time: Dayjs, timeString: string) => {
  //   console.log(time, timeString);
  // };
  const handleItemClick = (index: number) => {
    setSelectedItems((prevSelectedItems) => {
      const updatedSelectedItems = [...prevSelectedItems];
      updatedSelectedItems[index] = !updatedSelectedItems[index];
      return updatedSelectedItems;
    });
  };
// car fetching logic 



useEffect(() => {
  fetchCars();
}, []);

const fetchCars = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/api/client/cars`);
    // Access the cars array in the response
    setCars(response.data.cars);
    console.log("cars", response.data.cars);
  } catch (error) {
    console.error("There was an error fetching the cars!", error);
    toast.error("Failed to load cars.");
  }
};


//handle auth when clicking on rent now 
// Handler for "Rent Now" button
const handleRentNow = (carId: string) => {
  console.log("Car ID for future use:", carId); // Placeholder for future logic

  if (isAuthenticated) {
    
    navigate(`/pages/booking-checkout/${carId}`);
// Example of passing carId in state
  } else {
    // Redirect to /authentication/login if not authenticated
    navigate('/authentication/login');
  }
};

useEffect(() => {
  if (isAuthenticated) {
    const fetchWishlistCars = async () => {
      try {
        const response = await axiosInstance.get('/api/wishlist');
        setWishlistCars(response.data);
        // Store wishlist car IDs for easy lookup
        setWishlist(response.data.map((car) => car._id));
      } catch (error) {
        console.error('Error fetching wishlist cars:', error);
      }
    };

    fetchWishlistCars();
  } else {
    // Clear wishlist data if not authenticated
    setWishlistCars([]);
    setWishlist([]);
  }
}, [isAuthenticated]);

const handleWishlistClick = async (carId) => {
  if (!isAuthenticated) {
    // Redirect to register page
    navigate('/authentication/register');
    return;
  }

  try {
    if (wishlist.includes(carId)) {
      // Remove from wishlist
      await axiosInstance.delete(`/api/wishlist/${carId}`);
      setWishlist((prevWishlist) => prevWishlist.filter((id) => id !== carId));
      toast.error('Removed from the WishList successfully!');


    } else {
      // Add to wishlist
      await axiosInstance.post(`/api/wishlist/${carId}`);
      setWishlist((prevWishlist) => [...prevWishlist, carId]);
      toast.success('Added to the WishList successfully!');


    }
  } catch (error) {
    console.error('Error updating wishlist:', error);
  }
};
  const settings = {
    dots: false,
    nav: true,
    
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 567,
        settings: {
          slidesToShow: 1,
        },
      },
     
    ],
   
  };
  


  useEffect(() => {
    AOS.init({ duration: 1200, once: true });
  }, []);

  
  return (
    <>
    <Header />
      {/* Banner */}

      <section className="banner-section banner-slider">

        <div className="container">
          
       <ToastContainer  />

          <div className="home-banner">
            <div className="row align-items-center">
              <div className="col-lg-6" data-aos="fade-down">
                <p className="explore-text">
                  {" "}
                  <span>
                    <i className="fa-solid fa-thumbs-up me-2"></i>
                  </span>
                  100% Trusted car rental platform in the World
                </p>
                <h1>
                  <span>Find Your Best</span> <br />
                  Dream Car for Rental
                </h1>
                <p>
                Effortless rentals, endless choices. Find your perfect ride with ease and transparency on 
                our platform. Discover a wide range of vehicles, flexible options, and a seamless booking experience built just for you
                </p>
                <div className="view-all">
                  <Link
                    to={routes.listingGrid}
                    className="btn btn-view d-inline-flex align-items-center "
                  >
                    View all Cars{" "}
                    <span>
                      <i className="feather icon-arrow-right ms-2" />
                    </span>
                  </Link>
                </div>
              </div>
              <div className="col-lg-6" data-aos="fade-down">
                <div className="banner-imgs">
                  <ImageWithBasePath
                    src="assets/img/car-right.png"
                    className="img-fluid aos"
                    alt="bannerimage"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* /Banner */}
      {/* Search */}
      <div className="section-search">
        <SearchSection />
    
      </div>
        
      {/* Popular Services */}
      <section className="section popular-services popular-explore">
        <div className="container">
          {/* Heading title*/}
          <div className="section-heading" data-aos="fade-down">
            <h2>Explore Most Popular Cars</h2>
          
          </div>
          {/* /Heading title */}
          {/* car logos  */}
          <div className="row justify-content-center">
            <div className="col-lg-12" data-aos="fade-down">
              <div className="listing-tabs-group">
                <ul className="nav listing-buttons gap-3" data-bs-tabs="tabs">
                  <li>
                    <Link
                      className="active"
                      aria-current="true"
                      data-bs-toggle="tab"
                      to="#Carmazda"
                    >
                      <span>
                        <ImageWithBasePath
                          src="assets/img/icons/car-icon-01.svg"
                          alt="Mazda"
                        />
                      </span>
                      Mercedes
                    </Link>
                  </li>
                  <li>
                    <Link data-bs-toggle="tab" to="#Caraudi">
                      <span>
                        <ImageWithBasePath
                          src="assets/img/icons/car-icon-02.svg"
                          alt="Audi"
                        />
                      </span>
                      Audi
                    </Link>
                  </li>
                  <li>
                    <Link data-bs-toggle="tab" to="#Carhonda">
                      <span>
                        <ImageWithBasePath
                          src="assets/img/icons/car-icon-03.svg"
                          alt="Honda"
                        />
                      </span>
                      Honda
                    </Link>
                  </li>
                  <li>
                    <Link data-bs-toggle="tab" to="#Cartoyota">
                      <span>
                        <ImageWithBasePath
                          src="assets/img/icons/car-icon-04.svg"
                          alt="Toyota"
                        />
                      </span>
                      Toyota
                    </Link>
                  </li>
                  <li>
                    <Link data-bs-toggle="tab" to="#Caracura">
                      <span>
                        <ImageWithBasePath
                          src="assets/img/icons/car-icon-05.svg"
                          alt="Acura"
                        />
                      </span>
                      Acura
                    </Link>
                  </li>
                  <li>
                    <Link data-bs-toggle="tab" to="#Cartesla">
                      <span>
                        <ImageWithBasePath
                          src="assets/img/icons/car-icon-06.svg"
                          alt="Tesla"
                        />
                      </span>
                      Tesla
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* car logos  end  */}

          <div className="tab-content">
            <div className="tab-pane active" id="Carmazda">
              <div className="row">
                {/*  car card start */}
                {cars.length > 0 ? (  
                  
                cars.map((car) => (

                  <>
                    {/* col */}
                    
                    <div
                     key={car._id}
                      className="col-lg-4 col-md-6 col-12"
                      data-aos="fade-down"
                    >
                      <div className="listing-item">
                        <div className="listing-img">
                          {car.images && car.images.length > 0 ? (
                            <Link to={`${routes.listingDetails}/${car._id}`}>
                              <img
                                src={`${BASE_URL}/api/uploads/${car.images[0]}`} // Fetching the first image dynamically
                                className="img-fluid"
                                alt={car.name}
                              />
                            </Link>
                          ) : (
                            <div>No Image Available</div>
                          )}
                         <div className="fav-item justify-content-end">
                            <button
                              className={`fav-icon ${wishlist.includes(car._id) ? 'selected' : ''}`}
                              onClick={() => handleWishlistClick(car._id)}
                            >
                              <i className="feather icon-heart" />
                            </button>
                          </div>
                          <span className="featured-text">
                            {car.name}
                          </span>
                        </div>

                        <div className="listing-content">
                          <div className="listing-features d-flex align-items-end justify-content-between">
                            <div className="list-rating">
                              <Link
                                to="#"
                                className="author-img"
                              >
                                <ImageWithBasePath
                                  src="assets/img/profiles/avatar-03.jpg"
                                  alt="author"
                                />
                              </Link>
                              <h3 className="listing-title">
                                <Link to={routes.listingDetails}>
                                {car.name}

                                </Link>
                              </h3>
                              {/*  */}
                              <div className="list-rating">
                                {Array.from({ length: 5 }, (_, index) => {
                                  // const ratingValue = index + 1;
                                  const difference = car.averageRating - index;

                                  let starClass = 'far fa-star'; // Empty star

                                  if (difference >= 1) {
                                    // Full star
                                    starClass = 'fas fa-star filled';
                                  } else if (difference >= 0.5) {
                                    // Half star
                                    starClass = 'fas fa-star-half-alt filled';
                                  }

                                  return <i key={index} className={starClass} />;
                                })}
                                <span>
                                  ({car.averageRating}) {car.totalReviews} Reviews
                                </span>
                              </div>

                            </div>
                            <div className="list-km">
                              <span className="km-count">
                                <ImageWithBasePath
                                  src="assets/img/icons/map-pin.svg"
                                  alt="author"
                                />
                                3.5m
                              </span>
                            </div>
                          </div>
                          <div className="listing-details-group">
                            <ul>
                              <li>
                                <span>
                                  <ImageWithBasePath
                                    src="assets/img/icons/car-parts-05.svg"
                                    alt="Manual"
                                  />
                                </span>
                                <p>{car.transmission}</p>
                              </li>
                              <li>
                                <span>
                                  <ImageWithBasePath
                                    src="assets/img/icons/car-parts-02.svg"
                                    alt="10 KM"
                                  />
                                </span>
                                <p>{car.mileage} KM</p>
                              </li>
                              <li>
                                <span>
                                  <ImageWithBasePath
                                    src="assets/img/icons/car-parts-03.svg"
                                    alt="Petrol"
                                  />
                                </span>
                                <p>{car.fuelType}</p>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                <span>
                                  <ImageWithBasePath
                                    src="assets/img/icons/car-parts-04.svg"
                                    alt="Power"
                                  />
                                </span>
                                <p>Power</p>
                              </li>
                              <li>
                                <span>
                                  <ImageWithBasePath
                                    src="assets/img/icons/car-parts-05.svg"
                                    alt={car.registrationYear}
                                  />
                                </span>
                                <p>{car.registrationYear}</p>
                              </li>
                              <li>
                                <span>
                                  <ImageWithBasePath
                                    src="assets/img/icons/car-parts-06.svg"
                                    alt="Persons"
                                  />
                                </span>
                                <p>{car.seats}Persons</p>
                              </li>
                            </ul>
                          </div>
                          <div className="listing-location-details">
                            <div className="listing-price">
                              <span>
                                <i className="feather icon-map-pin" />
                              </span>
                              {car.city}

                            </div>
                            <div className="listing-price">
                              <h6>
                              MAD {car.pricePerDay}  <span>/ Day</span>
                              </h6>
                            </div>
                          </div>
                          <div className="mt-3">
                            <div className="listing-button">
                              <button
                                className="btn btn-order w-100"
                                
                                onClick={() => handleRentNow(car._id)}
                              >
                                <i className="feather icon-calendar me-2" ></i>
                                Rent Now
                              </button>
                            </div>
                          </div>
                          
                           {/* <div className="listing-button">
                            <Link
                              to={routes.listingDetails}
                              className="btn btn-order"
                            >
                              <span>
                                <i className="feather icon-calendar me-2" />
                              </span>
                              Rent Now
                            </Link>
                 
                          </div>  */}
                         
                        </div>
                      </div>
                    </div>
                    {/* /col */}
                  </>
                      ))
                    ) : (
                      <div className="no-results">
                        <p>No cars available for the selected criteria.</p>
                        <p>Please adjust your search parameters.</p>
                      </div>
                    )}
   

                  {/* car card ends hhhheh */}
              </div>
            </div>
            <div className="tab-pane fade" id="Caraudi">
              <div className="row">
                {/* col */}
                
                {/* col */}
                <div className="col-lg-4 col-md-6 col-12" data-aos="fade-down">
                  <div className="listing-item">
                    <div className="listing-img">
                      <Link to={routes.listingDetails}>
                        <ImageWithBasePath
                          src="assets/img/cars/car-05.jpg"
                          className="img-fluid"
                          alt="Audi"
                        />
                      </Link>
                      <div
                        className="fav-item"
                        key={12}
                        onClick={() => handleItemClick(12)}
                      >
                        <span className="featured-text">Chevrolet</span>
                        <Link
                          to="#"
                          className={`fav-icon ${
                            selectedItems[12] ? "selected" : ""
                          }`}
                        >
                          <i className="feather icon-heart" />
                        </Link>
                      </div>
                    </div>
                    <div className="listing-content">
                      <div className="listing-features">
                        <Link to="#" className="author-img">
                          <ImageWithBasePath
                            src="assets/img/profiles/avatar-05.jpg"
                            alt="author"
                          />
                        </Link>
                        <h3 className="listing-title">
                          <Link to={routes.listingDetails}>
                            2018 Chevrolet Camaro
                          </Link>
                        </h3>
                        <div className="list-rating">
                          <i className="fas fa-star filled" />
                          <i className="fas fa-star filled" />
                          <i className="fas fa-star filled" />
                          <i className="fas fa-star filled" />
                          <i className="fas fa-star filled" />
                          <span>(5.0)</span>
                        </div>
                      </div>
                      <div className="listing-details-group">
                        <ul>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-05.svg"
                                alt="Manual"
                              />
                            </span>
                            <p>Manual</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-02.svg"
                                alt="18 KM"
                              />
                            </span>
                            <p>18 KM</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-03.svg"
                                alt="Diesel"
                              />
                            </span>
                            <p>Diesel</p>
                          </li>
                        </ul>
                        <ul>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-04.svg"
                                alt="Power"
                              />
                            </span>
                            <p>Power</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-05.svg"
                                alt=""
                              />
                            </span>
                            <p>2018</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-06.svg"
                                alt="Persons"
                              />
                            </span>
                            <p>4 Persons</p>
                          </li>
                        </ul>
                      </div>
                      <div className="listing-location-details">
                        <div className="listing-price">
                          <span>
                            <i className="feather icon-map-pin" />
                          </span>
                          Germany
                        </div>
                        <div className="listing-price">
                          <h6>
                            $36 <span>/ Day</span>
                          </h6>
                        </div>
                      </div>
                      <div className="listing-button">
                        <Link
                          to={routes.listingDetails}
                          className="btn btn-order"
                        >
                          <span>
                            <i className="feather icon-calendar me-2" />
                          </span>
                          Rent Now
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                {/* /col */}
                
                {/* /col */}
              </div>
            </div>
            <div className="tab-pane fade" id="Carhonda">
              <div className="row">
                {/* col */}
                <div className="col-lg-4 col-md-6 col-12" data-aos="fade-down">
                  <div className="listing-item">
                    <div className="listing-img">
                      <Link to={routes.listingDetails}>
                        <ImageWithBasePath
                          src="assets/img/cars/car-08.jpg"
                          className="img-fluid"
                          alt="Toyota"
                        />
                      </Link>
                      <div
                        className="fav-item"
                        key={19}
                        onClick={() => handleItemClick(19)}
                      >
                        <span className="featured-text">Toyota</span>
                        <Link
                          to="#"
                          className={`fav-icon ${
                            selectedItems[19] ? "selected" : ""
                          }`}
                        >
                          <i className="feather icon-heart" />
                        </Link>
                      </div>
                    </div>
                    <div className="listing-content">
                      <div className="listing-features">
                        <Link to="#" className="author-img">
                          <ImageWithBasePath
                            src="assets/img/profiles/avatar-08.jpg"
                            alt="author"
                          />
                        </Link>
                        <h3 className="listing-title">
                          <Link to={routes.listingDetails}>
                            Toyota Tacoma 4WD
                          </Link>
                        </h3>
                        <div className="list-rating">
                          <i className="fas fa-star filled" />
                          <i className="fas fa-star filled" />
                          <i className="fas fa-star filled" />
                          <i className="fas fa-star filled" />
                          <i className="fas fa-star filled" />
                          <span>(5.0)</span>
                        </div>
                      </div>
                      <div className="listing-details-group">
                        <ul>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-01.svg"
                                alt="Auto"
                              />
                            </span>
                            <p>Auto</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-02.svg"
                                alt="22 miles"
                              />
                            </span>
                            <p>22 miles</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-03.svg"
                                alt="Diesel"
                              />
                            </span>
                            <p>Diesel</p>
                          </li>
                        </ul>
                        <ul>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-04.svg"
                                alt="Power"
                              />
                            </span>
                            <p>Power</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-05.svg"
                                alt=""
                              />
                            </span>
                            <p>2019</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-06.svg"
                                alt="Persons"
                              />
                            </span>
                            <p>5 Persons</p>
                          </li>
                        </ul>
                      </div>
                      <div className="listing-location-details">
                        <div className="listing-price">
                          <span>
                            <i className="feather icon-map-pin" />
                          </span>
                          Dallas, USA
                        </div>
                        <div className="listing-price">
                          <h6>
                            $30 <span>/ Day</span>
                          </h6>
                        </div>
                      </div>
                      <div className="listing-button">
                        <Link
                          to={routes.listingDetails}
                          className="btn btn-order"
                        >
                          <span>
                            <i className="feather icon-calendar me-2" />
                          </span>
                          Rent Now
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                {/* /col */}
                
                
                {/* /col */}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* /Popular Services */}
      {/* Popular Cartypes */}
      <section className="section popular-car-type">
        <div className="container">
          {/* Heading title*/}
          <div className="section-heading" data-aos="fade-down">
            <h2>Most Popular Cartypes</h2>
            <p>
            Most popular worldwide Car Category due to their reliability, affordability, and features.
            </p>
          </div>
          {/* /Heading title */}
          <div className="row">
            <div className="popular-slider-group ">
              <div className="popular-cartype-slider">
              <Slider {...settings} className="service-slider">
                <div className="listing-owl-item">
                  <div className="listing-owl-group">
                    <div className="listing-owl-img">
                      <ImageWithBasePath
                        src="assets/img/cars/mp-vehicle-01.png"
                        className="img-fluid"
                        alt="Popular Cartypes"
                      />
                    </div>
                    <h6>Dacia</h6>
                    <p>35 Cars</p>
                  </div>
                </div>
                <div className="listing-owl-item">
                  <div className="listing-owl-group">
                    <div className="listing-owl-img">
                      <ImageWithBasePath
                        src="assets/img/cars/mp-vehicle-02.png"
                        className="img-fluid"
                        alt="Popular Cartypes"
                      />
                    </div>
                    <h6>BMW</h6>
                    <p>45 Cars</p>
                  </div>
                </div>
                <div className="listing-owl-item">
                  <div className="listing-owl-group">
                    <div className="listing-owl-img">
                      <ImageWithBasePath
                        src="assets/img/cars/mp-vehicle-03.png"
                        className="img-fluid"
                        alt="Popular Cartypes"
                      />
                    </div>
                    <h6>Range Rover</h6>
                    <p>15 Cars</p>
                  </div>
                </div>
                <div className="listing-owl-item">
                  <div className="listing-owl-group">
                    <div className="listing-owl-img">
                      <ImageWithBasePath
                        src="assets/img/cars/mp-vehicle-04.png"
                        className="img-fluid"
                        alt="Popular Cartypes"
                      />
                    </div>
                    <h6>Pickup</h6>
                    <p>17 Cars</p>
                  </div>
                </div>
               
                <div className="listing-owl-item">
                  <div className="listing-owl-group">
                    <div className="listing-owl-img">
                      <ImageWithBasePath
                        src="assets/img/cars/mp-vehicle-05.png"
                        className="img-fluid"
                        alt="Popular Cartypes"
                      />
                    </div>
                    <h6>Family MPV</h6>
                    <p>24 Cars</p>
                  </div>
                </div>
              </Slider>
              </div>
            </div>
          </div>
          {/* View More */}
          <div className="view-all text-center" data-aos="fade-down">
            <Link
              to={routes.listingGrid}
              className="btn btn-view d-inline-flex align-items-center"
            >
              View all Cars{" "}
              <span>
                <i className="feather icon-arrow-right ms-2" />
              </span>
            </Link>
          </div>
          {/* View More */}
        </div>
      </section>
      {/* /Popular Cartypes */}
      {/* Facts By The Numbers */}
      <section className="section facts-number">
        <div className="facts-left">
          <ImageWithBasePath
            src="assets/img/bg/facts-left.png"
            className="img-fluid"
            alt="facts left"
          />
        </div>
        <div className="facts-right">
          <ImageWithBasePath
            src="assets/img/bg/facts-right.png"
            className="img-fluid"
            alt="facts right"
          />
        </div>
        <div className="container">
          {/* Heading title*/}
          <div className="section-heading" data-aos="fade-down">
            <h2 className="title text-white">Facts By The Numbers</h2>
            <p className="description text-white">
            Here are some dreamsrent interesting facts presented by the numbers
            </p>
          </div>
          {/* /Heading title */}
          <div className="counter-group">
            <div className="row">
              <div
                className="col-lg-3 col-md-6 col-12 d-flex"
                data-aos="fade-down"
              >
                <div className="count-group flex-fill">
                  <div className="customer-count d-flex align-items-center">
                    <div className="count-img">
                      <ImageWithBasePath
                        src="assets/img/icons/bx-heart.svg"
                        alt=""
                      />
                    </div>
                    <div className="count-content">
                      <h4>
                        <CountUp
                          className="counterUp"
                          end={16000}
                          duration={3}
                          separator=","
                        />
                        K<br />
                      </h4>
                      <p> Happy Customer </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-3 col-md-6 col-12 d-flex"
                data-aos="fade-down"
              >
                <div className="count-group flex-fill">
                  <div className="customer-count d-flex align-items-center">
                    <div className="count-img">
                      <ImageWithBasePath
                        src="assets/img/icons/bx-car.svg"
                        alt=""
                      />
                    </div>
                    <div className="count-content">
                      <h4>
                        <CountUp
                          className="counterUp"
                          end={2547}
                          duration={3}
                          separator=","
                        />
                        +<br />
                      </h4>
                      <p>Count of Cars</p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-3 col-md-6 col-12 d-flex"
                data-aos="fade-down"
              >
                <div className="count-group flex-fill">
                  <div className="customer-count d-flex align-items-center">
                    <div className="count-img">
                      <ImageWithBasePath
                        src="assets/img/icons/bx-headphone.svg"
                        alt=""
                      />
                    </div>
                    <div className="count-content">
                      <h4>
                        <CountUp
                          className="counterUp"
                          end={625}
                          duration={3}
                          separator=","
                        />
                        K+
                        <br />
                      </h4>
                      <p>Car Center Solutions</p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-3 col-md-6 col-12 d-flex"
                data-aos="fade-down"
              >
                <div className="count-group flex-fill">
                  <div className="customer-count d-flex align-items-center">
                    <div className="count-img">
                      <ImageWithBasePath
                        src="assets/img/icons/bx-history.svg"
                        alt=""
                      />
                    </div>
                    <div className="count-content">
                      <h4>
                        <CountUp
                          className="counterUp"
                          end={200}
                          duration={3}
                          separator=","
                        />
                        K+
                        <br />
                      </h4>
                      <p>Total Kilometer</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* /Facts By The Numbers */}
      {/* Rental deals */}
   
                     
      {/* /Rental deals */}
      {/* Why Choose Us */}
      <section className="section why-choose popular-explore">
        <div className="choose-left">
          <ImageWithBasePath
            src="assets/img/bg/choose-left.png"
            className="img-fluid"
            alt="Why Choose Us"
          />
        </div>
        <div className="container">
          {/* Heading title*/}
          <div className="section-heading" data-aos="fade-down">
            <h2>Why Choose Us</h2>
            {/* <p>
              Lorem Ipsum has been the industry standard dummy text ever since
              the 1500s,
            </p> */}
          </div>
          {/* /Heading title */}
          <div className="why-choose-group">
            <div className="row">
              <div
                className="col-lg-4 col-md-6 col-12 d-flex"
                data-aos="fade-down"
              >
                <div className="card flex-fill">
                  <div className="card-body">
                    <div className="choose-img choose-black">
                      <ImageWithBasePath
                        src="assets/img/icons/bx-selection.svg"
                        alt=""
                      />
                    </div>
                    <div className="choose-content">
                      <h4>Effortless  &amp; Transparent Booking</h4>
                      <p>
                      Book your car effortlessly with a clear, upfront process—no hidden fees or surprises.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-4 col-md-6 col-12 d-flex"
                data-aos="fade-down"
              >
                <div className="card flex-fill">
                  <div className="card-body">
                    <div className="choose-img choose-secondary">
                      <ImageWithBasePath
                        src="assets/img/icons/bx-crown.svg"
                        alt=""
                      />
                    </div>
                    <div className="choose-content">
                      <h4> Convenient Pickup Locations</h4>
                      <p>
                      Choose from a vast network of pickup points, making rentals accessible wherever you are.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-4 col-md-6 col-12 d-flex"
                data-aos="fade-down"
              >
                <div className="card flex-fill">
                  <div className="card-body">
                    <div className="choose-img choose-primary">
                      <ImageWithBasePath
                        src="assets/img/icons/bx-user-check.svg"
                        alt=""
                      />
                    </div>
                    <div className="choose-content">
                      <h4>Customer Satisfaction  &amp;  Trust   </h4>
                      <p>
                      Enjoy a secure, transparent rental experience with responsive support and trusted reviews.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* /Why Choose Us */}
      {/* About us Testimonials */}
      
      {/* About us Testimonials */}
      {/* FAQ  */}
      <section className="section faq-section bg-light-primary">
        <div className="container">
          {/* Heading title*/}
          <div className="section-heading" data-aos="fade-down">
            <h2>Frequently Asked Questions </h2>
            {/* <p>Find answers to your questions from our previous answers</p> */}
          </div>
          {/* /Heading title */}
          <div className="faq-info">
            <div className="faq-card bg-white" data-aos="fade-down">
              <h4 className="faq-title">
                <Link
                  className="collapseds"
                  data-bs-toggle="collapse"
                  to="#faqOne"
                  aria-expanded="true"
                >
                  How old do I need to be to rent a car?
                </Link>
              </h4>
              <div id="faqOne" className="card-collapse collapse show">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat.
                </p>
              </div>
            </div>
            <div className="faq-card bg-white" data-aos="fade-down">
              <h4 className="faq-title">
                <Link
                  className="collapsed"
                  data-bs-toggle="collapse"
                  to="#faqTwo"
                  aria-expanded="false"
                >
                  What documents do I need to rent a car?
                </Link>
              </h4>
              <div id="faqTwo" className="card-collapse collapse">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat.
                </p>
              </div>
            </div>
            <div className="faq-card bg-white" data-aos="fade-down">
              <h4 className="faq-title">
                <Link
                  className="collapsed"
                  data-bs-toggle="collapse"
                  to="#faqThree"
                  aria-expanded="false"
                >
                  What types of vehicles are available for rent?
                </Link>
              </h4>
              <div id="faqThree" className="card-collapse collapse">
                <p>
                  We offer a diverse fleet of vehicles to suit every need,
                  including compact cars, sedans, SUVs and luxury vehicles. You
                  can browse our selection online or contact us for assistance
                  in choosing the right vehicle for you
                </p>
              </div>
            </div>
            <div className="faq-card bg-white" data-aos="fade-down">
              <h4 className="faq-title">
                <Link
                  className="collapsed"
                  data-bs-toggle="collapse"
                  to="#faqFour"
                  aria-expanded="false"
                >
                  Can I rent a car with a debit card?
                </Link>
              </h4>
              <div id="faqFour" className="card-collapse collapse">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat.
                </p>
              </div>
            </div>
            <div className="faq-card bg-white" data-aos="fade-down">
              <h4 className="faq-title">
                <Link
                  className="collapsed"
                  data-bs-toggle="collapse"
                  to="#faqFive"
                  aria-expanded="false"
                >
                  What is your fuel policy?
                </Link>
              </h4>
              <div id="faqFive" className="card-collapse collapse">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat.
                </p>
              </div>
            </div>
            <div className="faq-card bg-white" data-aos="fade-down">
              <h4 className="faq-title">
                <Link
                  className="collapsed"
                  data-bs-toggle="collapse"
                  to="#faqSix"
                  aria-expanded="false"
                >
                  Can I add additional drivers to my rental agreement?
                </Link>
              </h4>
              <div id="faqSix" className="card-collapse collapse">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat.
                </p>
              </div>
            </div>
            <div className="faq-card bg-white" data-aos="fade-down">
              <h4 className="faq-title">
                <Link
                  className="collapsed"
                  data-bs-toggle="collapse"
                  to="#faqSeven"
                  aria-expanded="false"
                >
                  What happens if I return the car late?
                </Link>
              </h4>
              <div id="faqSeven" className="card-collapse collapse">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* /FAQ */}
      
      {/* /Pricing Plan */}
      <>
        {/* Blog Section */}
        {/* <section className="blog-section news-section pt-0">
          <div className="container"> */}
            {/* Heading title*/}
            {/* <div className="section-heading" data-aos="fade-down">
              <h2>News &amp; Insights For You</h2>
              <p>This blog post provides valuable insights into the benefits</p>
            </div> */}
            {/* /Heading title */}
            {/* <div className="row">
              <div className="col-lg-4 col-md-6 d-lg-flex">
                <div className="blog grid-blog">
                  <div className="blog-image">
                    <Link to={routes.blogDetails}>
                      <ImageWithBasePath
                        className="img-fluid"
                        src="assets/img/blog/blog-4.jpg"
                        alt="Post Image"
                      />
                    </Link>
                  </div>
                  <div className="blog-content">
                    <p className="blog-category">
                      <Link to="#">
                        <span>Journey</span>
                      </Link>
                    </p>
                    <h3 className="blog-title">
                      <Link to={routes.blogDetails}>
                        The 2023 Ford F-150 Raptor – A First Look
                      </Link>
                    </h3>
                    <p className="blog-description">
                      Covers all aspects of the automotive industry with a focus
                      on accessibility
                    </p>
                    <ul className="meta-item mb-0">
                      <li>
                        <div className="post-author">
                          <div className="post-author-img">
                            <ImageWithBasePath
                              src="assets/img/profiles/avatar-04.jpg"
                              alt="author"
                            />
                          </div>
                          <Link to="#">
                            {" "}
                            <span> Hellan </span>
                          </Link>
                        </div>
                      </li>
                      <li className="date-icon">
                        <i className="fa-solid fa-calendar-days" />{" "}
                        <span>October 6, 2022</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 d-lg-flex">
                <div className="blog grid-blog">
                  <div className="blog-image">
                    <Link to={routes.blogDetails}>
                      <ImageWithBasePath
                        className="img-fluid"
                        src="assets/img/blog/blog-3.jpg"
                        alt="Post Image"
                      />
                    </Link>
                  </div>
                  <div className="blog-content">
                    <p className="blog-category">
                      <Link to="#">
                        <span>Tour &amp; tip</span>
                      </Link>
                    </p>
                    <h3 className="blog-title">
                      <Link to={routes.blogDetails}>
                        Tesla Model S: Top Secret Car Collector’s Garage
                      </Link>
                    </h3>
                    <p className="blog-description">
                      Catering to driving enthusiasts, Road &amp; Track provides
                      engaging content on...
                    </p>
                    <ul className="meta-item mb-0">
                      <li>
                        <div className="post-author">
                          <div className="post-author-img">
                            <ImageWithBasePath
                              src="assets/img/profiles/avatar-13.jpg"
                              alt="author"
                            />
                          </div>
                          <Link to="#">
                            {" "}
                            <span> Alphonsa Daniel </span>
                          </Link>
                        </div>
                      </li>
                      <li className="date-icon">
                        <i className="fa-solid fa-calendar-days" />{" "}
                        <span>March 6, 2023</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 d-lg-flex">
                <div className="blog grid-blog">
                  <div className="blog-image">
                    <Link to={routes.blogDetails}>
                      <ImageWithBasePath
                        className="img-fluid"
                        src="assets/img/blog/blog-10.jpg"
                        alt="Post Image"
                      />
                    </Link>
                  </div>
                  <div className="blog-content">
                    <p className="blog-category">
                      <Link to="#">
                        <span>Updates</span>
                      </Link>
                    </p>
                    <h3 className="blog-title">
                      <Link to={routes.blogDetails}>
                        Dedicated To Cars, Covering Everything
                      </Link>
                    </h3>
                    <p className="blog-description">
                      Known for its irreverent take on car culture, offers a mix
                      of news, reviews...
                    </p>
                    <ul className="meta-item mb-0">
                      <li>
                        <div className="post-author">
                          <div className="post-author-img">
                            <ImageWithBasePath
                              src="assets/img/profiles/avatar-13.jpg"
                              alt="author"
                            />
                          </div>
                          <Link to="#">
                            {" "}
                            <span> Hellan</span>
                          </Link>
                        </div>
                      </li>
                      <li className="date-icon">
                        <i className="fa-solid fa-calendar-days" />{" "}
                        <span>March 6, 2023</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="view-all text-center aos-init aos-animate"
              data-aos="fade-down"
            >
              <Link
                to={routes.blogList}
                className="btn btn-view-custom d-inline-flex align-items-center"
              >
                View all Blogs{" "}
                <span>
                  <i className="feather icon-arrow-right ms-2" />
                </span>
              </Link>
            </div>
          </div> */}
        {/* </section> */}
        {/* /Blog Section */}
      </>
      <Footer/>
    </>
  );
};

export default HomeOne;
