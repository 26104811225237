import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { all_routes } from "../router/all_routes";
import ImageWithBasePath from "../../core/data/img/ImageWithBasePath";
import { useDispatch, useSelector } from "react-redux";
import { set_is_mobile_sidebar } from "../../core/data/redux/action";
import { useAuth } from "../../core/context/AuthContext"; 
const Header = () => {
  const routes = all_routes;
  const location = useLocation();

  const dispatch = useDispatch();

  const mobileSidebar = useSelector((state: any) => state.mobileSidebar);

  const handleClick = () => {
    dispatch(set_is_mobile_sidebar(!mobileSidebar));
  };

  const [mobileSubmenu, setMobileSubmenu] = useState(false);

  const mobileSubmenus = () => {
    setMobileSubmenu(!mobileSubmenu);
  };


    // Destructure the authentication state and methods
    const { isAuthenticated, client, logout } = useAuth();

    // Handler for logout
    const handleLogout = () => {
      logout();
      // Optionally, navigate to a specific route after logout
    };
  

  return (
    <>
      <header className="header">
        <div className="container-fluid">
          <nav className="navbar navbar-expand-lg header-nav">
            <div className="navbar-header">
              <Link id="mobile_btn" to="#" onClick={handleClick}>
                <span className="bar-icon">
                  <span />
                  <span />
                  <span />
                </span>
              </Link>
              <Link to={routes.homeOne} className="navbar-brand logo">
                <ImageWithBasePath
                  src="assets/img/logo.svg"
                  className="img-fluid"
                  alt="Logo"
                />
              </Link>
              <Link to={routes.homeOne} className="navbar-brand logo-small">
                <ImageWithBasePath
                  src="assets/img/logo-small.png"
                  className="img-fluid"
                  alt="Logo"
                />
              </Link>
            </div>
            <div className="main-menu-wrapper">
              <div className="menu-header">
                <Link to={routes.homeOne} className="menu-logo">
                  <ImageWithBasePath
                    src="assets/img/logo.svg"
                    className="img-fluid"
                    alt="Logo"
                  />
                </Link>
                <Link
                  id="menu_close"
                  className="menu-close"
                  to="#"
                  onClick={handleClick}
                >
                  {" "}
                  <i className="fas fa-times" />
                </Link>
              </div>
              <ul className="main-nav">
                <li
                  className={`has-submenu ${location.pathname.includes("index") ? "active" : ""}`}
                >
                  <Link to={routes.homeOne} onClick={mobileSubmenus}>
                    Home 
                    
                  </Link>
                  
                </li>
                <li
                  className={`has-submenu ${location.pathname.includes("listing") ? "active" : ""}`}
                >
                  <Link to={routes.listingGrid}>
                    Listings 
                  </Link>
                  
                </li>
              
                <li
                  className={`has-submenu ${location.pathname.includes("pages") ? "active" : ""}`}
                >
                  <Link to={routes.aboutUs}>
                    About Us 

                  </Link>
                </li>
                  
                     
                <li
                  className={`has-submenu ${location.pathname.includes("blog") ? "active" : ""}`}
                >
                  <Link to={routes.blogList}>
                    Blog 
               
                  </Link>
                
                </li>
                <li
                  className={
                    location.pathname.includes(routes.contactUs) ? "active" : ""
                  }
                >
                  <Link to={routes.contactUs}>Contact</Link>
                </li>
                {!isAuthenticated && (
                <>
                  <li className="login-link d-lg-none">
                    <Link to={routes.register}>Sign Up</Link>
                  </li>
                  <li className="login-link d-lg-none">
                    <Link to={routes.login}>Sign In</Link>
                  </li>
                </>
              )}
              {/* Mobile-Only Authenticated User Menu */}
              {isAuthenticated && (
                <li className="has-submenu d-lg-none">
                  <a href="#" className="d-flex align-items-center">
                    <i className="fa-regular fa-user me-2" aria-hidden="true"></i>
                    <span>{client.clientname}</span>
                  </a>
                  <ul className="dropdown-menu">
                    <li>
                      <Link className="dropdown-item" to="/user/user-wishlist">
                        <i className="fa-solid fa-heart me-2"></i>Wishlist
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/user/user-dashboard">
                        <i className="fa-solid fa-tachometer-alt me-2"></i>Dashboard
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/user/user-reviews">
                        <i className="fa-solid fa-star me-2"></i>Reviews
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/user/user-messages">
                        <i className="fa-solid fa-envelope me-2"></i>Messages
                      </Link>
                    </li>
                    <li><hr className="dropdown-divider" /></li>
                    <li>
                      <button className="dropdown-item" onClick={handleLogout}>
                        <i className="fa-solid fa-sign-out-alt me-2"></i>Logout
                      </button>
                    </li>
                  </ul>
                </li>
              )}
              </ul>
            </div>
            <ul className="navbar-nav ms-auto d-none d-lg-flex">
            {isAuthenticated && client ? (
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle d-flex align-items-center"
                  href="#"
                  id="userDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i className="fa-regular fa-user me-2" aria-hidden="true"></i>
                  <span>{client.clientname}</span>
                </a>
                <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="userDropdown">
                  <li>
                    <Link className="dropdown-item" to="/user/user-wishlist">
                      <i className="fa-solid fa-heart me-2"></i>Wishlist
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/user/user-dashboard">
                      <i className="fa-solid fa-tachometer-alt me-2"></i>Dashboard
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/user/user-reviews">
                      <i className="fa-solid fa-star me-2"></i>Reviews
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/user/user-messages">
                      <i className="fa-solid fa-envelope me-2"></i>Messages
                    </Link>
                  </li>
                  <li><hr className="dropdown-divider" /></li>
                  <li>
                    <button className="dropdown-item" onClick={handleLogout}>
                      <i className="fa-solid fa-sign-out-alt me-2"></i>Logout
                    </button>
                  </li>
                </ul>
              </li>
            ) : (
              <>
                <li className="nav-item me-2">
                  <Link className="btn btn-primary" to={routes.login}>
                    <i className="fa-regular fa-user me-2" aria-hidden="true"></i>
                    Sign In
                  </Link>
                </li>
                <li className="nav-item me-2">
                  <Link className="btn btn-secondary" to={routes.register}>
                    <i className="fa-solid fa-lock me-2" aria-hidden="true"></i>
                    Sign Up
                  </Link>
                </li>
              </>
            )}
          </ul>

          </nav>
        </div>
      </header>
    </>
  );
};

export default Header;
