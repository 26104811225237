import React, { createContext, useState, ReactNode } from 'react';

// Define the shape of your reservation data
// Define the shape of your reservation data
interface ReservationData {
    car: any; // Replace 'any' with the actual type if available
    rentalType: string;
    sameReturnLocation: boolean;
    startDate: Date | null;
    returnDate: Date | null;
    totalPrice: number;
    deliveryLocation: string;
    returnLocation: string;
    // Personal information fields
    firstName: string;
    lastName: string;
    address: string;
    country: string;
    city: string;
    email: string;
    phoneNumber: string;
    additionalInfo: string;
    drivingLicenceNumber: string;
    drivingLicenceDocument: File | null;
    termsAccepted: boolean;
    // Add other fields as needed
  }
  

// Define the context type
interface ReservationContextType {
  reservationData: ReservationData;
  setReservationData: React.Dispatch<React.SetStateAction<ReservationData>>;
}

// Create the context without a default value
export const ReservationContext = createContext<ReservationContextType | undefined>(undefined);

// Define props for ReservationProvider
interface ReservationProviderProps {
  children: ReactNode;
}

export const ReservationProvider: React.FC<ReservationProviderProps> = ({ children }) => {
    const [reservationData, setReservationData] = useState<ReservationData>({
        car: null,
        rentalType: 'delivery',
        sameReturnLocation: true,
        startDate: null,
        returnDate: null,
        totalPrice: 0,
        deliveryLocation: '',
        returnLocation: '',
        firstName: '',
        lastName: '',
        address: '',
        country: '',
        city: '',
        email: '',
        phoneNumber: '',
        additionalInfo: '',
        drivingLicenceNumber: '',
        drivingLicenceDocument: null,
        termsAccepted: false,
        // Add other fields as needed
      });
      

  return (
    <ReservationContext.Provider value={{ reservationData, setReservationData }}>
      {children}
    </ReservationContext.Provider>
  );
};
