

import React, { useState, useEffect, FormEvent } from "react";
import ImageWithBasePath from "../../core/data/img/ImageWithBasePath";
import { Link } from "react-router-dom";
import { CornerDownLeft } from "react-feather";
import { all_routes } from "../router/all_routes";
import { useNavigate } from 'react-router-dom';

import { useLocation } from "react-router-dom";
import AuthService from '../../core/services/authService';
import { toast, ToastContainer } from 'react-toastify';

const routes = all_routes;

const ResetPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // Custom hook to parse query parameters
  const useQuery = () => {
    return new URLSearchParams(location.search);
  };

  const query = useQuery();
  const token = query.get("token") || "";
  const id = query.get("id") || "";

  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [isToggle, setIsToggle] = useState<boolean>(false);
  const [isToggle1, setIsToggle1] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [success, setSuccess] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!token || !id) {
      setError("Invalid password reset link.");
    }
  }, [token, id]);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setError("");
    setSuccess("");

    // Basic Validation
    if (!password || !confirmPassword) {
      toast.error("Please fill in all fields.");
      return;
    }
    const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
    if (!passwordRegex.test(password)) {
      toast.error("Password must be at least 8 characters long and include at least one letter and one number.");
      return;
    }

    if (password !== confirmPassword) {
      toast.error("Passwords do not match.");
      return;
    }

    try {
      setLoading(true);
      await AuthService.resetPassword(id ,token, password ); // Removed 'response' as it's unused
      toast.success("Password has been reset successfully. Redirecting to login...");
      setLoading(false);
      
      // Redirect after a short delay
      setTimeout(() => {
        navigate(routes.login); // Ensure 'routes.login' is correctly defined
      }, 3000);
    } catch (err: any) { // Adjust the type based on your error structure
      setLoading(false);
      toast.error(err.message || "Error resetting password.");
    }
  };
  return (
    <div className="main-wrapper login-body">
      <ToastContainer />

      {/* Header */}
      <header className="log-header">
        <Link to={routes.homeOne}>
          <ImageWithBasePath
            className="img-fluid logo-dark"
            src="assets/img/logo.svg"
            alt="Logo"
          />
        </Link>
      </header>
      {/* /Header */}
      <div className="login-wrapper">
        <div className="loginbox">
          <div className="login-auth">
            <div className="login-auth-wrap">
              <div className="sign-group">
                <Link to={routes.homeOne} className="btn sign-up">
                  <span>
                    <CornerDownLeft />
                  </span>
                  Back To Home
                </Link>
              </div>
              <h1>Reset Password</h1>
              {error && <div className="alert alert-danger">{error}</div>}
              {success && <div className="alert alert-success">{success}</div>}
              <p className="account-subtitle">
                Your new password must be different from previous used
                passwords.
              </p>
              <form onSubmit={handleSubmit} >
                <div className="input-block">
                  <label className="form-label">
                    New Password <span className="text-danger">*</span>
                  </label>
                  <div
                      className="pass-group"
                      onClick={() => setIsToggle(!isToggle)}
                    >
                      <input
                       type={isToggle ? "text" : "password"}
                       className="form-control pass-input"
                       placeholder="Enter new password"
                       value={password}
                       onChange={(e) => setPassword(e.target.value)}
                       required
                      />
                      <span
                        className={`fas toggle-password ${
                          isToggle ? "fa-eye" : "fa-eye-slash"
                        }`}
                      />
                    </div>
                </div>
                <div className="input-block">
                  <label className="form-label">
                    Confirm Password <span className="text-danger">*</span>
                  </label>
                  <div
                      className="pass-group"
                      onClick={() => setIsToggle1(!isToggle1)}
                    >
                      <input
                         type={isToggle1 ? "text" : "password"}
                         className="form-control pass-input"
                         placeholder="Confirm new password"
                         value={confirmPassword}
                         onChange={(e) => setConfirmPassword(e.target.value)}
                         required
                      />
                      <span
                        className={`fas toggle-password ${
                          isToggle1 ? "fa-eye" : "fa-eye-slash"
                        }`}
                      />
                    </div>
                </div>
                <button
                  type="submit"
                  className="btn btn-outline-light w-100 btn-size"
                  disabled={loading}
                >
                  {loading ? (
              <>
                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                Saving...
              </>
            ) : "Save Changes"}
                </button>
              </form>
         
              <Link to={routes.login} className="btn btn-link mt-3">
                <CornerDownLeft size={16} /> Back to Login
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/* Footer */}
      <footer className="log-footer">
        <div className="container-fluid">
          {/* Copyright */}
          <div className="copyright">
            <div className="copyright-text">
              <p>© 2023 Dreams Rent. All Rights Reserved.</p>
            </div>
          </div>
          {/* /Copyright */}
        </div>
      </footer>
      {/* /Footer */}
    </div>
  );
};

export default ResetPassword;
