// src/core/services/authService.ts
import axios from 'axios';
import BASE_URL from '../../config';
import localStorageService from './localStorageService';
import axiosInstance from './axiosInstance';

interface Client {
  clientId: string;
  clientname: string;
  email: string;
  // Add other client properties as needed
}

class AuthServiceClass {
  async register(clientname: string, email: string, password: string) {
    try {
      const response = await axios.post(`${BASE_URL}/api/client/auth/register`, { clientname, email, password });
      const { token, client } = response.data;

      this.setSession(token);
      this.setUser(client);

      return { token, client };
    } catch (error: any) {
      throw new Error(error.response?.data?.message || 'Error registering');
    }
  }

  async login(email: string, password: string) {
    try {
      const response = await axios.post(`${BASE_URL}/api/client/auth/login`, { email, password });
      const { token, client, message } = response.data;

      this.setSession(token);
      this.setUser(client);

      return { token, client, message };
    } catch (error: any) {
      throw new Error(error.response?.data?.message || 'Error logging in');
    }
  }

  async forgotPassword(email: string) {
    try {
      const response = await axios.post(`${BASE_URL}/api/client/auth/forgot-password`, { email });
      return response.data;
    } catch (error: any) {
      throw new Error(error.response?.data?.message || 'Error requesting password reset');
    }
  }

  async resetPassword(clientId: string, token: string, password: string) {
    try {
      const response = await axios.post(`${BASE_URL}/api/client/auth/reset-password`, {
        clientId,
        token,
          password
          
         });
      return response.data;
    } catch (error: any) {
      throw new Error(error.response?.data?.message || 'Error resetting password');
    }
  }

  logout() {
    this.setSession(null);
    this.removeUser();
  }

  setSession(token: string | null) {
    if (token) {
      localStorage.setItem('jwt_token', token);
      axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
      localStorage.removeItem('jwt_token');
      delete axiosInstance.defaults.headers.common['Authorization'];
    }
  }

  setUser(client: Client) {
    localStorageService.setItem('auth_client', client);
  }

  getUser(): Client | null {
    return localStorageService.getItem<Client>('auth_client');
  }

  removeUser() {
    localStorageService.removeItem('auth_client');
  }
}

const AuthService = new AuthServiceClass();
export default AuthService;
