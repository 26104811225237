import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import axios from "axios";
import BASE_URL from '../../config';


const BlogDetails = () => {
  const { blogId } = useParams();
  const [blog, setBlog] = useState(null);

  useEffect(() => {
    const fetchBlogDetails = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/api/blogs/${blogId}`);
            console.log(response.data); // Ensure to log and check the structure
            setBlog(response.data.post); 
        } catch (error) {
            console.error("Error fetching blog details:", error);
        }
    };

    fetchBlogDetails();
}, [blogId]);


  if (!blog) {
    return <div>Loading...</div>;
  }

  const renderContentSections = () => {
    if (!blog.sections || blog.sections.length === 0) {
      return <p>No additional content available.</p>;
    }

    return blog.sections.map((section, index) => (
      <div key={index} style={{ marginBottom: '1rem' }}>
        <h3>{section.title}</h3>
        <p>{section.content}</p>
        {index < blog.sections.length - 1 && <hr />}
      </div>
    ));
  };

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString("en-US", {
      year: 'numeric', month: 'long', day: 'numeric'
    });
  };

  return (
    <>
      <div className="blogbanner" style={{ 
              backgroundImage: `url(${BASE_URL}/api/uploads/${blog.mainImage})`, // Set background image dynamically
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat'
          }}>
          <div className="blogbanner-content">
            {/* Optional category hint like in your well-styled example */}
            <span className="blog-hint">Category</span> {/* Replace 'Category' with a dynamic category if available */}
            
            {/* Blog Title */}
            <h1>{blog.title}</h1>

            {/* Meta Information */}
            <ul className="entry-meta meta-item justify-content-center">
              {/* Author Information */}
              <li>
                <div className="post-author">
                  <div className="post-author-img">
                    {/* Using a custom component for handling image paths might be beneficial as in your well-styled example */}
                    <img
                      className="img-fluid"
                      src={`${BASE_URL}/api/uploads/${blog.mainImage}`} // Dynamic image from the database
                      alt={blog.title}
                      
                    />
                  </div>
                  <Link to="#">
                    {/* <span>{blog.authorName || 'Admin'}</span> */}
                  </Link>
                </div>
              </li>

              {/* Publication Date */}
              <li className="date-icon">
                <i className="fa-solid fa-calendar-days" />
                <span>{formatDate(blog.createdAt)}</span>
              </li>
            </ul>
          </div>
        </div>


      <div className="blog-section">
        <div className="container">
          {renderContentSections()}
        </div>
      </div>
    </>
  );
};

export default BlogDetails;
