import React, { useState , useEffect } from "react";
import Breadcrumbs from "../common/breadcrumbs";
import { Link } from "react-router-dom";
import ImageWithBasePath from "../../core/data/img/ImageWithBasePath";
import { all_routes } from "../router/all_routes";
import BASE_URL from '../../config';
import { ToastContainer } from 'react-toastify';
import axios from 'axios';

const routes = all_routes;
const BlogList = () => {
  const [blogs, setBlogs] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [blogsPerPage] = useState(2); 
  useEffect(() => {
    fetchBlogs();
  }, []);

  const fetchBlogs = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/blogs`);
      setBlogs(response.data);
    } catch (error) {
      console.error("There was an error fetching the blogs!", error);
    }
  };
  const indexOfLastBlog = currentPage * blogsPerPage;
  const indexOfFirstBlog = indexOfLastBlog - blogsPerPage;
  const currentBlogs = blogs.slice(indexOfFirstBlog, indexOfLastBlog);

  // Change page
  const paginate = pageNumber => setCurrentPage(pageNumber);

  return (
    <>
      <div className="main-wrapper">
        <Breadcrumbs title="Blog List" subtitle="Blogs" />
        {/* Blog List*/}
        <div className="blog-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                <div className="row">
                  {currentBlogs.map(blog => (
                    <div key={blog._id} className="col-lg-12 col-md-12 d-lg-flex">
                      <div className="blog grid-blog">
                        <div className="blog-image-list">
                          <Link to={`/blog/blog-details/${blog._id}`} >
                            <img
                              className="img-fluid"
                              src={`${BASE_URL}/api/uploads/${blog.mainImage}`} // Dynamic image from the database
                              alt="Blog Post"
                            />
                          </Link>
                        </div>
                        <div className="blog-content">
                          <div className="blog-list-date">
                            <ul className="meta-item-list">
                              <li>
                                <div className="post-author">
                                  <div className="post-author-img">
                                    <ImageWithBasePath
                                      src="assets/img/profiles/avatar-13.jpg" // Dynamic profile image if needed
                                      alt="author"
                                    />
                                  </div>
                                  <Link to={`/blog/blog-details/${blog._id}`} >
                                    {/* This should dynamically pull the author name if included in the model */}
                                    <span>{blog.authorName || 'Admin'}</span>
                                  </Link>
                                </div>
                              </li>
                              <li className="date-icon ms-3">
                                <i className="fa-solid fa-calendar-days" />
                                {/* Ensure blog has a timestamp attribute, otherwise use customUpdatedAt */}
                                <span>{new Date(blog.customUpdatedAt || blog.createdAt).toLocaleDateString()}</span>
                              </li>
                            </ul>
                          </div>
                          <h3 className="blog-title">
                            <Link to={`/blog/blog-details/${blog._id}`} >{blog.title}</Link>
                          </h3>
                          {/* Display the first section's content as a preview; ensure sections exist */}
                          <p className="blog-description">
                            {blog.sections.length > 0 ? blog.sections[0].content : "No content available"}
                          </p>

                          <Link to={`/blog/blog-details/${blog._id}`} className="viewlink btn btn-primary justify-content-center">
                            Read More <i className="feather icon-arrow-right ms-2" />
                          </Link>
                        </div>
                      </div>
                    </div>
                  ))}
                  <ToastContainer />
                </div>

                {/*Pagination*/}
                <div className="pagination">
                  <nav>
                    <ul className="pagination mt-0">
                      <li className="previtem">
                        <Link className={`page-link ${currentPage === 1 ? 'disabled' : ''}`} onClick={() => currentPage > 1 && paginate(currentPage - 1)}>
                          <i className="fas fa-regular fa-arrow-left me-2" /> Prev
                        </Link>
                      </li>
                      <li className="justify-content-center pagination-center">
                        <div className="page-group">
                          <ul>
                            {[...Array(Math.ceil(blogs.length / blogsPerPage)).keys()].map(number => (
                              <li key={number + 1} className={`page-item ${currentPage === number + 1 ? 'active' : ''}`}>
                                <Link className="page-link" onClick={() => paginate(number + 1)}>
                                  {number + 1}
                                  {currentPage === number + 1 && <span className="visually-hidden">(current)</span>}
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </li>
                      <li className="nextlink">
                        <Link className={`page-link ${currentPage === Math.ceil(blogs.length / blogsPerPage) ? 'disabled' : ''}`} onClick={() => currentPage < Math.ceil(blogs.length / blogsPerPage) && paginate(currentPage + 1)}>
                          Next <i className="fas fa-regular fa-arrow-right ms-2" />
                        </Link>
                      </li>
                    </ul>
                  </nav>
                </div>
                {/*/Pagination*/}

              </div>
              <div className="col-lg-4 theiaStickySidebar">
                <div className="stickybar">
                  <div className="rightsidebar">
                    <div className="card">
                      <h4>
                        <ImageWithBasePath
                          src="assets/img/icons/details-icon.svg"
                          alt="details-icon"
                        />{" "}
                        Filter
                      </h4>
                      <div className="filter-content looking-input input-block mb-0">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="To Search type and hit enter"
                        />
                      </div>
                    </div>
                    <div className="card">
                      <h4>
                        <ImageWithBasePath
                          src="assets/img/icons/category-icon.svg"
                          alt="details-icon"
                        />{" "}
                        Categories
                      </h4>
                      <ul className="blogcategories-list">
                        <li>
                          <Link to="#">Accept Credit Cards</Link>
                        </li>
                        <li>
                          <Link to="#">Smoking Allowed</Link>
                        </li>
                        <li>
                          <Link to="#">Bike Parking</Link>
                        </li>
                        <li>
                          <Link to="#">Street Parking</Link>
                        </li>
                        <li>
                          <Link to="#">Wireless Internet</Link>
                        </li>
                        <li>
                          <Link to="#">Pet Friendly</Link>
                        </li>
                      </ul>
                    </div>
                    <div className="card tags-widget">
                      <h4>
                        <i className="feather icon-tag" /> Tags
                      </h4>
                      <ul className="tags">
                        <li>Air </li>
                        <li>Engine </li>
                        <li>Item </li>
                        <li>On Road </li>
                        <li>Rims </li>
                        <li>Speed </li>
                        <li>Make </li>
                        <li>Transmission </li>
                      </ul>
                    </div>
                    <div className="card">
                      <h4>
                        <i className="feather icon-tag" />
                        Top Article
                      </h4>
                      <div className="article">
                        <div className="article-blog">
                          <Link to={routes.blogDetails}>
                            <ImageWithBasePath
                              className="img-fluid"
                              src="assets/img/blog/blog-3.jpg"
                              alt="Blog"
                            />
                          </Link>
                        </div>
                        <div className="article-content">
                          <h5>
                            <Link to={routes.blogDetails}>
                              Great Business Tips in 2023
                            </Link>
                          </h5>
                          <div className="article-date">
                            <i className="fa-solid fa-calendar-days" />
                            <span>Jan 6, 2023</span>
                          </div>
                        </div>
                      </div>
                      <div className="article">
                        <div className="article-blog">
                          <Link to={routes.blogDetails}>
                            <ImageWithBasePath
                              className="img-fluid"
                              src="assets/img/blog/blog-4.jpg"
                              alt="Blog"
                            />
                          </Link>
                        </div>
                        <div className="article-content">
                          <h5>
                            <Link to={routes.blogDetails}>
                              Excited News About Cars.
                            </Link>
                          </h5>
                          <div className="article-date">
                            <i className="fa-solid fa-calendar-days" />
                            <span>Feb 5, 2023</span>
                          </div>
                        </div>
                      </div>
                      <div className="article">
                        <div className="article-blog">
                          <Link to={routes.blogDetails}>
                            <ImageWithBasePath
                              className="img-fluid"
                              src="assets/img/blog/blog-5.jpg"
                              alt="Blog"
                            />
                          </Link>
                        </div>
                        <div className="article-content">
                          <h5>
                            <Link to={routes.blogDetails}>
                              8 Amazing Tricks About Business
                            </Link>
                          </h5>
                          <div className="article-date">
                            <i className="fa-solid fa-calendar-days" />
                            <span>March 10, 2023</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /Blog Grid*/}
      </div>
    </>
  );
};

export default BlogList;
